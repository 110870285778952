import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

// Components
import { ListingCount, H2, H4, HeaderContentWrapper, Pager, ListElement, ContentWrapper, TablesWrapper, ListTableHeader, Button, Error } from 'es-components';
import { PageLoader } from 'es-containers';
import { auth, StaffUsersList } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

interface StaffListQueryData {
  ticketWindow: { allStaffUsers: StaffUsersList };
}

interface StaffListQueryVars {
  search?: string;
  page?: number;
}

const GET_STAFF_USERS_QUERY = gql`
  query allStaffUsers($search: String, $page: Int) {
    ticketWindow {
      allStaffUsers(page: $page, filter: { search: $search }) {
        staffUserCount
        stafUsers {
          id
          email
          avatar
          fullName
          dateOfBirth
          lastLogin
          profilePictureUrl
        }
      }
    }
  }
`;

export const StaffList = ({ history, location, match }: RouteComponentProps): JSX.Element => {
  const startPage = match.params['page'] || 1;
  const filterString = match.params['filter'] === 'undefined' ? '' : match.params['filter'];
  const [search, setSearch] = useState(filterString);
  const [page, setPage] = useState(startPage);
  const [staffList, setStaffList] = useState(null);
  const [pageDataUpdating, setPageDataUpdating] = useState(false);

  // lazy query
  const [getStaffList, { data: staffListData, loading: staffListLoading, error: staffListError }] = useLazyQuery<StaffListQueryData, StaffListQueryVars>(
    GET_STAFF_USERS_QUERY
  );

  useEffect(() => {
    if (staffList == null && !pageDataUpdating) {
      getStaffList({
        variables: { search: '', page: 1 }
      });
      setPageDataUpdating(true);
    }
  }, []);

  if (pageDataUpdating && !staffListLoading && staffListData != null && staffListError == null) {
    setStaffList(staffListData);
    setPageDataUpdating(false);
  }

  const onSearch = search => {
    setSearch(search);
    setPage(1);
    getStaffList({
      variables: { search, page: 1 }
    });
    setPageDataUpdating(true);
  };

  const onPageChange = page => {
    setPage(page);
    getStaffList({
      variables: { search, page }
    });
    setPageDataUpdating(true);
  };

  const getCreateButton = () => {
    const currentUserRole = auth.getPermissions().canWriteStaffUsers;

    if (currentUserRole) {
      return (
        <Link to={`/create_staff/`}>
          <Button sizeW="wide" title="Add Staff" icon="IoMdPersonAdd" kind="green" round="10px" fontSize="1.3rem" />
        </Link>
      );
    } else {
      return null;
    }
  };

  const renderBody = () => {
    if (staffListLoading) {
      return <PageLoader show={staffListLoading} />;
    }
    if (!staffListLoading && (staffListError || staffListData === null)) {
      return <Error />;
    }

    if (!staffListLoading && staffListData != null && staffList != null) {
      const { staffUserCount, stafUsers } = staffListData.ticketWindow.allStaffUsers;
      const currentRecordsCount = stafUsers.length;

      return (
        <React.Fragment>
          <ListingCount allElements={staffUserCount.toString()} currentCount={currentRecordsCount.toString()} />
          <Pager totalRecords={staffUserCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
          {stafUsers.map((data, index) => {
            return (
              <Link key={data.id} to={`/staff/${data.id}/${page}/${search}`}>
                <ListElement
                  listType="staff"
                  userImage={data.profilePictureUrl || data.avatar}
                  key={data.id}
                  id={`staffUserCard_${index}`}
                  name={data.fullName}
                  email={data.email}
                  date={data.lastLogin}
                  position={''}
                />
              </Link>
            );
          })}
          <Pager totalRecords={staffUserCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
        </React.Fragment>
      );
    }
  };

  const { canReadStaffUsers } = auth.getPermissions();
  if (!canReadStaffUsers) {
    return (
      <HeaderContentWrapper backgroundColor="greyLightTint" paddingTop="spacingTiny" paddingBottom="spacingTiny" height="65px">
        <H4 light color="greyBlackShade" size="1.5rem">
          Not Authorized to view Staff List
        </H4>
      </HeaderContentWrapper>
    );
  }

  return (
    <ContentWrapper backgroundColor="white">
      <HeaderContentWrapper backgroundColor="greyLightTint" paddingTop="spacingTiny" paddingBottom="spacingTiny" height="65px">
        <H2 light color="greyBlackShade" size="3rem">
          Staff List
        </H2>
        {getCreateButton()}
      </HeaderContentWrapper>
      <TablesWrapper>
        <ListTableHeader value={search} searchTitle="Search..." onSearch={onSearch} />
        {renderBody()}
      </TablesWrapper>
    </ContentWrapper>
  );
};
