import { Cookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import check from '../components/utils/access';
class Auth {
  cookies = new Cookies();
  authenticated = this.cookies.get('jwt') ? true : false;

  isAuthenticated() {
    const permissions = this.cookies.get('permissions');
    return permissions != null && this.authenticated;
  }

  getAuthenticatedUserId(): string {
    try {
      return jwt.decode(this.cookies.get('jwt')).user_id;
    } catch (e) {
      return null;
    }
  }

  setBaseUrl(baseUrl) {
    this.cookies.set('baseUrl', baseUrl);
  }

  getBaseUrl(): string {
    return this.cookies.get('baseUrl');
  }

  getToken(): string {
    return this.cookies.get('jwt');
  }

  getPermissions() {
    try {
      const permissions = this.cookies.get('permissions');
      return permissions;
    } catch (e) {
      return null;
    }
  }

  setUserRoleId(id: string) {
    const setCookieSecure = process.env.NODE_ENV === 'development' ? false : true;
    this.cookies.set('userRoleId', id, { path: '/', secure: setCookieSecure });
  }

  setPermissions(userPermissions) {
    const canReadGuests = check(userPermissions, 'guests.read');
    const canWriteGuests = check(userPermissions, 'guests.write');
    const canReadReports = check(userPermissions, 'reports.read');
    const canWriteReports = check(userPermissions, 'reports.write');
    const canReadStaffUsers = check(userPermissions, 'staffUsers.read');
    const canWriteStaffUsers = check(userPermissions, 'staffUsers.write');
    const canReadRedemptions = check(userPermissions, 'redemptions.read');
    const canWriteRedemptions = check(userPermissions, 'redemptions.write');
    const permissions = {
      canReadGuests,
      canWriteGuests,
      canReadReports,
      canWriteReports,
      canReadStaffUsers,
      canWriteStaffUsers,
      canReadRedemptions,
      canWriteRedemptions
    };
    const setCookieSecure = process.env.NODE_ENV === 'development' ? false : true;
    this.cookies.set('permissions', JSON.stringify(permissions), { path: '/', secure: setCookieSecure });
  }

  authenticate(jwt: string) {
    const setCookieSecure = process.env.NODE_ENV === 'development' ? false : true;
    this.authenticated = true;
    this.cookies.set('jwt', jwt, { path: '/', secure: setCookieSecure });
  }

  signout() {
    this.authenticated = false;
    this.cookies.remove('jwt', { path: '/' });
    this.cookies.remove('permissions', { path: '/' });
    this.cookies.remove('userRoleId', { path: '/' });
  }
}

const auth = new Auth();

export default auth;
