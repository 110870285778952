import React from 'react';
import styled from 'styled-components';

// Components
import { Icon } from 'es-components';

interface InputGroupI {
  width?: string;
  height?: string;
}

const InputGroup = styled.div<InputGroupI>`
  width: ${props => (props.width ? props.width : `249px`)};
  height: ${props => (props.height ? props.height : `auto`)};
  position: relative;
`;

const ClearBtn = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => props.theme.grey};
  right: 2px;
  top: 2px;
  cursor: pointer;
  margin-right: 25px;

  &:hover {
    color: ${props => props.theme.greyBlackShade};
  }
`;

interface SearchInputI {
  borderRadius?: string;
}

const SearchInput = styled.input<SearchInputI>`
  width: 100%;
  height: 40px;
  padding: 0.5em;
  background: ${props => props.theme.greyLightTint};
  border: 1px solid ${props => props.theme[props.color] || props.theme.grey};
  color: ${props => props.theme.greyBlackShade};
  outline: none;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : `10px`)};
`;

const SearchIconWrapper = styled.div`
  float: right;
  margin-top: -34px;
  margin-right: 10px;
  position: relative;
  color: ${props => props.theme.greyBlackShade};
`;

interface SearchTextInputProps {
  onEnterClick?: () => void;
  onChangeSearchInput?: (searchString: string) => void;
  value?: string;
  onClear?: (val: string) => void;
  borderRadius?: string;
  width?: string;
  color?: string;
  height?: string;
  searchTitle?: string;
  id?: string;
}

export const SearchTextInput = (props: SearchTextInputProps): JSX.Element => {
  let searchInputRef = React.useRef();
  let [searchInputValue, setSearchInputValue] = React.useState('');
  if (!searchInputValue) {
    searchInputValue = props.value;
  }

  const onKeyPressHandler = key => {
    if (key.charCode === 13 && props.onEnterClick) {
      props.onEnterClick();
    }
  };

  const inputChangeHandler = () => {
    let currentValue = searchInputRef || { current: { value: null } };
    if (props.onChangeSearchInput) {
      props.onChangeSearchInput(currentValue.current.value);
    }
    setSearchInputValue(currentValue.current.value);
  };

  const clearSearchFilter = () => {
    setSearchInputValue('');
    if (props.onClear) {
      props.onClear('');
    }
  };

  const showClearBtn = searchInputValue !== '';

  return (
    <InputGroup width={props.width} height={props.height}>
      <SearchInput
        borderRadius={props.borderRadius}
        onKeyPress={onKeyPressHandler}
        color={props.color}
        type="text"
        id={props.id}
        ref={searchInputRef}
        value={searchInputValue}
        onChange={inputChangeHandler}
        placeholder={props.searchTitle}
      />
      <SearchIconWrapper>
        <Icon name="IoIosSearch" size="1.6em" />
      </SearchIconWrapper>
      {showClearBtn && props.onClear && (
        <ClearBtn onClick={clearSearchFilter}>
          <Icon name="IoMdCloseCircleOutline" size="1.6em" />
        </ClearBtn>
      )}
    </InputGroup>
  );
};
