import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { gql } from 'apollo-boost/lib/index';
import { useLazyQuery } from '@apollo/react-hooks';

// Components
import {
  HeaderContentWrapper,
  ContentWrapper,
  TablesWrapper,
  HeaderReportGenerator,
  ListItemWrapper,
  ListGrid,
  ListItem,
  Par,
  Pager,
  Error,
  Icon,
  H2
} from 'es-components';
import { PageLoader } from 'es-containers';
import { Report } from 'es-libs';

interface GetReportsVars {
  page?: number;
}

interface GetReportsData {
  ticketWindow: { allReports: { reportCount: number; reports: Report[] } };
}

const GET_REPORTS_QUERY = gql`
  query allReports($page: Int) {
    ticketWindow {
      allReports(page: $page, filter: { reportType: redemption }) {
        reportCount
        reports {
          id
          created
          userName
          fileUrl
        }
      }
    }
  }
`;

export const RedemptionReports = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [reportsList, setReportsList] = useState(null);
  const [pageDataUpdating, setPageDataUpdating] = useState(false);

  //lazy query
  const [getReports, { data: reportsQueryData, loading: reportsQueryLoading, error: reportsQueryError }] = useLazyQuery<GetReportsData, GetReportsVars>(
    GET_REPORTS_QUERY
  );

  useEffect(() => {
    if (reportsList == null && !pageDataUpdating) {
      getReports({
        variables: { page }
      });
      setPageDataUpdating(true);
    }
  }, []);

  if (pageDataUpdating && !reportsQueryLoading && reportsQueryData != null && reportsQueryError == null) {
    setReportsList(reportsQueryData);
    setPageDataUpdating(false);
  }

  const onPageChange = page => {
    setPage(page);
    getReports({
      variables: { page }
    });
    setPageDataUpdating(true);
  };

  const onReportGenerated = () => {
    getReports({
      variables: { page: 1 }
    });
    setPageDataUpdating(true);
  };

  const formatDate = (dateToFormat: string): string => {
    let date = '';
    if (dateToFormat) {
      const dateFormat = window.localStorage.getItem('dateFormat') || 'MM/DD/YYYY';
      let separator = '/';
      if (dateFormat.indexOf('-') != -1) {
        separator = '-';
      } else if (dateFormat.indexOf('/') != -1) {
        separator = '/';
      }
      const year = dateToFormat.substr(0, 4);
      const month = dateToFormat.substr(5, 2);
      const day = dateToFormat.substr(8, 2);

      const formatArray = dateFormat.split(separator);
      formatArray.forEach((element, index) => {
        switch (element) {
          case 'MM':
            date += month;
            break;
          case 'DD':
            date += day;
            break;
          case 'YYYY':
            date += year;
            break;
        }

        if (index < 2) {
          date += separator;
        }
      });
    }
    return date;
  };

  const renderBody = () => {
    if (reportsQueryLoading) {
      return <PageLoader show={reportsQueryLoading} />;
    }

    if (!reportsQueryLoading && (reportsQueryError || reportsQueryData === null)) {
      return <Error />;
    }

    if (!reportsQueryLoading && reportsQueryData != null && reportsList != null) {
      let { reports, reportCount } = reportsList.ticketWindow.allReports;

      reports = reports.sort((a, b) => {
        const aDate = moment(a.created).unix();
        const bDate = moment(b.created).unix();
        if (aDate > bDate) {
          return -1;
        }
        if (aDate < bDate) {
          return 1;
        }

        return 0;
      });

      return (
        <>
          <Pager totalRecords={reportCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
          <ListGrid
            listHeaders={[
              { title: 'Name', align: 'left' },
              { title: 'Dates', align: 'left' },
              { title: 'Download', align: 'center' }
            ]}
            listColWidths="1fr 1fr 1fr">
            {reports.map((report, index) => (
              <ListItemWrapper key={report.id} difRowColor id={`reportRow_${index}`}>
                <ListItem>
                  <Par size="1rem" color="greyDark">
                    {report.userName}
                  </Par>
                </ListItem>
                <ListItem align="left">
                  <Par size="1rem" color="greyDark">
                    {formatDate(report.created)}
                  </Par>
                </ListItem>
                <ListItem align="center">
                  {report.fileUrl && (
                    <a href={report.fileUrl} download>
                      <Icon name="IoIosCloudDownload" size="1.3rem" />
                    </a>
                  )}
                </ListItem>
              </ListItemWrapper>
            ))}
          </ListGrid>
          <Pager totalRecords={reportCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
        </>
      );
    }
  };

  return (
    <ContentWrapper backgroundColor="white">
      <HeaderContentWrapper backgroundColor="greyLightTint" paddingTop="spacingTiny" paddingBottom="spacingTiny" height="65px">
        <H2 light color="greyBlackShade" size="3rem">
          Redemption Reports
        </H2>
      </HeaderContentWrapper>
      <TablesWrapper>
        <HeaderReportGenerator onReportAdd={onReportGenerated} />
        {renderBody()}
      </TablesWrapper>
    </ContentWrapper>
  );
};
