import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';

//Validations
import { validateAll, validateRequired, validatePassword } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Components
import { PageWrapper, ContentWrapper, AuthWrapper, LoginForm, AuthLogoBanner, TextInput, AuthFooter, FormErrorMsg } from 'es-components';
import img from '../../images/BackgroundFour.jpeg';

interface SetPasswordVars {
  token: string;
  passwordConfirmation: string;
  password: string;
}

interface SetPasswordData {
  ticketWindow: { setPassword: { id: string } };
}

const SET_PASSWORD_MUTATION = gql`
  mutation SetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    ticketWindow {
      setPassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        id
      }
    }
  }
`;

const PasswordRecovery = ({ history, match }: RouteComponentProps): JSX.Element => {
  const { addToast } = useToasts();

  const [errorMsg, setErrorMsg] = useState('');
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!match.params || !match.params['token']) {
      throw new Error('There is not a valid token in the URL');
    } else {
      setToken(match.params['token']);
    }
  }, []);

  const clearState = () => {
    setErrorMsg('');
    setErrorOnSubmit(false);
  };

  // mutations
  const [setPassword, { loading }] = useMutation<SetPasswordData, SetPasswordVars>(SET_PASSWORD_MUTATION);

  const handleOnSubmit = (values: { passwordConfirmation: string; password: string }) => {
    setPassword({ variables: { ...values, token } })
      .then(res => {
        clearState();
        history.push('/login');
        addToast('Your password has been reset successfully.', { appearance: 'success', autoDismiss: true });
      })
      .catch(error => {
        const newError = error.graphQLErrors ? error.graphQLErrors.map(x => x.message) : '';
        setErrorMsg(newError);
        setErrorOnSubmit(true);
      });
  };

  return (
    <PageWrapper bgImage={img}>
      <AuthWrapper direction="column" padding="spacingMed">
        <ContentWrapper backgroundColor="primaryShade" padding="1rem 1rem 0">
          <LoginForm loading={loading} id="login-form" buttonText="Change Password" onSubmitHandler={values => handleOnSubmit(values)}>
            <AuthLogoBanner />
            {errorOnSubmit && <FormErrorMsg>{errorMsg}</FormErrorMsg>}
            <TextInput
              type="password"
              validate={validateAll([validateRequired, validatePassword])}
              validateOnChange
              validationErrorHasLayout
              field="password"
              textColor="white"
              icon="IoIosUnlock"
              label="Password*"
              borderRadius="0"
              backgroundColor="headerActive"
              height="50px"
              autoComplete="off"
              id="password"
            />
            <TextInput
              type="password"
              validate={validateAll([validateRequired, validatePassword])}
              validateOnChange
              validationErrorHasLayout
              field="passwordConfirmation"
              textColor="white"
              label="Confirm Password*"
              borderRadius="0"
              icon="IoIosUnlock"
              backgroundColor="headerActive"
              height="50px"
              autoComplete="off"
              id="passwordConfirmation"
            />
          </LoginForm>
          <AuthFooter backgroundColor="primaryShade" linkTo="/login" linkPar="Back to" linkLabel="Login page" />
        </ContentWrapper>
      </AuthWrapper>
    </PageWrapper>
  );
};

export default PasswordRecovery;
