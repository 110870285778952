import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

export const BasicForm = styled(Form)`
  width: 100%;
`;

export default function StaffForm({ children, onSubmit, ...props }): JSX.Element {
  return (
    <BasicForm onSubmit={values => onSubmit(values)} {...props}>
      {children}
    </BasicForm>
  );
}
