import { Common } from '../commons';

export function getCurrentFrontEndUrl(): string {
  const { protocol, host, href } = window.location;
  const FRONT_END_URL = process.env.FRONT_END_URL;

  // from settings
  let urlFromEnvVar;
  if (FRONT_END_URL != null) {
    urlFromEnvVar = Common.FRONT_END_URLS[FRONT_END_URL];
  }

  // from url line
  let currentUrl = protocol + '//' + host + '/';
  if (href.indexOf('localhost') > -1) {
    currentUrl = Common.FRONT_END_URLS.ENTABENI;
  }

  return urlFromEnvVar || currentUrl;
}
