import React from 'react';

// Components
import styled from 'styled-components';
import { H2 } from 'es-components';

import { indyPassesDataOptions } from '../../selectorsData/indyPassesData';

const CardHeader = styled.header`
  display: block;
  min-height: 50px;
  color: ${props => props.theme.greyShade};
  background-color: ${props => props.theme.greyLight};
  padding: 0.8rem 1rem;
`;

interface RedemptionCardWrapperI {
  minHeight?: string;
  marginBottom?: string;
  border?: string;
  rows?: string;
}

export const RedemptionCardWrapper = styled.article<RedemptionCardWrapperI>`
  width: auto;
  height: auto;
  min-height: ${props => props.minHeight || 'auto'};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  box-shadow: ${props => props.theme.boxShadow};
  border: ${props => props.border || props.theme.StdBorder};
  background-color: ${props => props.theme.white};

  display: grid;
  grid-template-rows: ${props => props.rows || 'auto 1fr auto'};
`;

export const RedemptionDate = styled.div`
  float: right;
  font: 0.8rem;
  font-weight: 900;
  margin-top: -25px;
  color: ${props => props.theme.greyDark};
`;

export const RedemptionRecord = ({ title, date }: { title: string; date: string }): JSX.Element => {
  const level = indyPassesDataOptions.find(option => option.value === title);
  let levelName = title;
  if (level) {
    levelName = level.label;
  }

  return (
    <RedemptionCardWrapper marginBottom="spacingTiny">
      <CardHeader>
        <H2 size="1.1rem" marginBottom="spacingTiny">
          {levelName}
        </H2>
        <RedemptionDate>{date}</RedemptionDate>
      </CardHeader>
    </RedemptionCardWrapper>
  );
};
