import styled from 'styled-components';

interface CardWrapperI {
  borderRadius?: string;
  minHeight?: string;
  marginBottom?: string;
  border?: string;
  rows?: string;
}

export const CardWrapper = styled.article<CardWrapperI>`
  width: auto;
  height: auto;
  border-radius: ${props => props.borderRadius || '14px 0 0 0'};
  min-height: ${props => props.minHeight || 'auto'};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  box-shadow: ${props => props.theme.boxShadow};
  border: ${props => props.border || props.theme.StdBorder};
  background-color: ${props => props.theme.white};
  display: grid;
  grid-template-rows: ${props => props.rows || 'auto 1fr auto'};
`;

export const SiteWrapper = styled.main`
  margin: 0;
  min-height: 100%;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: auto 1fr auto;
`;

interface ContentWrapperI {
  paddingLeft?: string;
  paddingRight?: string;
  border?: string;
  height?: string;
  marginTop?: string;
  paddingTop?: string;
  marginBottom?: string;
  maxWidth?: string;
  padding?: string;
  backgroundColor?: string;
}

export const ContentWrapper = styled.section<ContentWrapperI>`
  width: 100%;
  display: block;
  position: relative;
  padding-left: ${props => props.paddingLeft || 0};
  padding-right: ${props => props.paddingRight || 0};
  border: ${props => props.border || 0};
  height: ${props => props.height || 'auto'};
  margin-top: ${props => props.theme[props.marginTop] || 0};
  margin-right: ${props => (props.theme.maxWidth ? 'auto' : 0)};
  padding-top: ${props => props.theme[props.paddingTop] || 0};
  padding-bottom: 1%;
  margin-bottom: ${props => props.theme[props.marginBottom] || props.marginBottom || 0};
  max-width: ${props => props.maxWidth && props.theme.maxWidth};
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.transparent};
`;

export const TablesWrapper = styled.div`
  padding-left: 2%;
  padding-right: 2%;
`;

interface HeaderContentWrapperI {
  paddingLeft?: string;
  paddingRight?: string;
  border?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  maxWidth?: string;
  backgroundColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export const HeaderContentWrapper = styled.div<HeaderContentWrapperI>`
  width: 100%;
  position: relative;
  display: block;
  padding-left: ${props => props.paddingLeft || '2%'};
  padding-right: ${props => props.paddingRight || '2%'};
  border: ${props => props.border || 0};
  height: ${props => props.height || '65px'};
  margin-top: ${props => props.theme[props.marginTop] || 0};
  margin-right: ${props => (props.theme.maxWidth ? 'auto' : 0)};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  max-width: ${props => props.maxWidth && props.theme.maxWidth};
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.transparent};
  padding-top: ${props => props.theme[props.paddingTop] || 0};
  padding-bottom: ${props => props.theme[props.paddingBottom] || 0};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface PageWrapperI {
  maxWidth?: string;
  border?: string;
  bgImage?: string;
  backgroundColor?: string;
}

export const PageWrapper = styled.article<PageWrapperI>`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: block;
  min-width: 300px;
  max-width: ${props => props.maxWidth && props.theme.maxWidth};
  border: ${props => props.border || 0};
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.white};
  background-image: url(${props => props.theme.backgroundImage || props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
`;
