import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PictureEditor from '@entabeni-systems/react-picture-editor';

// Components
import styled from 'styled-components';
import {
  ImageCropper,
  Icon,
  Button,
  PersonalInformation,
  EmploymentInformation,
  ContactInformation,
  UploadModal,
  FormErrorMsg,
  StaffForm
} from 'es-components';
import { RoleOptionI } from './staffInfoComponents/StaffInfoInterface';
import { useToasts } from 'react-toast-notifications';

// libs
import { auth, Guest } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

interface CreateStaffVars {
  email?: string;
  password?: string;
  $passwordConfirmation?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  sex?: string;
  phone?: string;
  profilePictureUrl?: string;
  employmentTypeIds?: string[];
  roleIds?: string[];
}

interface CreateStaffData {
  ticketWindow: { createStaffUser: Guest };
}

const CREATE_STAFF_MUTATION = gql`
  mutation CreateStaffUser(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $firstName: String
    $lastName: String
    $phone: String
    $dateOfBirth: String
    $sex: String
    $profilePictureUrl: String
    $employmentTypeIds: [String]
    $roleIds: [String]
  ) {
    ticketWindow {
      createStaffUser(
        email: $email
        password: $password
        passwordConfirmation: $passwordConfirmation
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        dateOfBirth: $dateOfBirth
        sex: $sex
        profilePictureUrl: $profilePictureUrl
        roleIds: $roleIds
        employmentTypeIds: $employmentTypeIds
      ) {
        id
      }
    }
  }
`;

interface UserImageI {
  userImage?: string;
}

interface PhotoSidePartI extends UserImageI {
  border?: string;
}

const PhotoSidePart = styled.div<PhotoSidePartI>`
  margin-left: 10px;
  height: ${props => (props.userImage !== '' ? '430px' : `380px`)};
  width: 320px;
  color: ${props => props.theme.greyShade};
  background-color: ${props => props.theme.greyLightTint};
  border: ${props => props.border || props.theme.StdBorder};
  border-radius: 14px 0 0 0;
  box-shadow: ${props => props.theme.boxShadow};
`;

const StaffInfoBlock = styled.div`
  margin-top: 15px;
  padding: 8px;
`;

const StaffDetailWrapper = styled.div`
  display: flex;
  min-height: 50px;
  margin-top: 10px;
  flex-direction: row;
  background-color: ${props => props.theme.white};
  padding: 0.8rem 1rem;
  border-radius: 14px 0 0 0;
`;

const PassInfoDetails = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const IconConteiner = styled.div`
  margin-left: 45px;
  margin-top: 20px;
`;

const PhotoPart = styled.div`
  height: 280px;
  width: 316px;
  border-radius: 12px 0 0 0;
  color: ${props => props.theme.grey};
  border-bottom: 2px solid;
  border-color: ${props => props.theme.grey};
`;

const ButtonsBlock = styled.div`
  float: right;
  display: flex;
  flex-direction: row;
`;

const UserImage = styled.div<UserImageI>`
  background-image: url(${props => props.userImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
  width: 316px;
  border-radius: 12px 0 0 0;
`;

export const CreateStaffBody = ({ rolesOptions }: { rolesOptions: RoleOptionI[] }): JSX.Element => {
  const [editorMode, setEditorMode] = useState(false);
  const [photoUploadModalOpen, setPhotoUploadModalOpen] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [loaderPostfix, setLoaderPostfix] = useState(Math.random() * 10000);
  const [uploading, setUploading] = useState(false);
  const [createdUserId, setCreatedUserId] = useState(null);
  const [isImgLoadedInEditor, setIsImgLoadedInEditor] = useState(false);
  const [currentImageData, setCurrentImageData] = useState(undefined);

  const { addToast } = useToasts();

  //mutation
  const [createStaffUser, { loading }] = useMutation<CreateStaffData, CreateStaffVars>(CREATE_STAFF_MUTATION);

  const handleOnSubmit = (values: any) => {
    if (profilePictureUrl != null) {
      values['profilePictureUrl'] = profilePictureUrl;
    }
    if (values.sex) {
      values.sex = values.sex.value;
    }
    if (values.roleIds && values.roleIds.length > 0) {
      const val = [];
      values.roleIds.forEach(v => {
        if (v) {
          val.push(v.value);
        }
      });
      values.roleIds = val;
    }

    createStaffUser({ variables: { ...values } })
      .then(({ data }) => {
        if (data) {
          setCreatedUserId(data.ticketWindow.createStaffUser.id);
        }
      })
      .catch(error => {
        const newError = error.graphQLErrors ? error.graphQLErrors.map(x => x.message) : '';
        setErrorOnSubmit(true);
        setErrorMsg(newError);
      });
  };

  const showModalHandler = () => {
    setPhotoUploadModalOpen(!photoUploadModalOpen);
    if (photoUploadModalOpen) {
      setEditorMode(false);
    }
  };

  const onPreviewImageHandler = (isImgLoadedInEditor: boolean, imageData: PictureEditor) => {
    setIsImgLoadedInEditor(isImgLoadedInEditor);
    if (currentImageData == null) {
      setCurrentImageData(imageData);
    }
  };

  const onSubmitImageModalHandler = () => {
    const image = currentImageData.getImageFromCanvas();
    if (image) {
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          const fileName = 'avatar';
          const fileType = `${fileName}.jpg`;
          blob['name'] = fileType;

          let imageData = new File([blob], fileType, {
            type: blob.type
          });

          uploadFile(imageData);
        });
    }
  };

  const updateEditorModeHandler = isEnabled => {
    setEditorMode(isEnabled);
  };

  const getImageBlock = () => {
    if (profilePictureUrl) {
      return <UserImage userImage={profilePictureUrl + '?' + loaderPostfix} />;
    } else {
      return (
        <PhotoPart>
          <IconConteiner>
            <Icon name="IoMdImage" size="14em" />
          </IconConteiner>
        </PhotoPart>
      );
    }
  };

  const uploadFile = async file => {
    setUploading(true);
    const urlData = await fetch(`${auth.getBaseUrl()}/signUrl?userId=${createdUserId}&uploadType=avatar`, {
      method: 'GET',
      mode: 'cors',
      headers: { Authorization: auth.getToken(), 'Content-Type': 'application/json', 'X-Key-Inflection': 'camel' }
    });
    urlData.json().then(async json => {
      const awsImage = await fetch(json.signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file ? file.type : '' },
        body: file
      });
      if (awsImage) {
        setUploading(false);
        setProfilePictureUrl(awsImage.url.split('?')[0]);
        setLoaderPostfix(Math.random() * 10000);
        showModalHandler();
        addToast('Image uploaded successfully.', { appearance: 'success', autoDismiss: true });
      }
    });
  };

  const extraActionBtnConfig = {
    clickHandler: () => setEditorMode(false),
    show: editorMode,
    styling: { marginRight: 'auto' },
    title: 'Choose another file'
  };

  if (createdUserId) {
    return <Redirect to={`/staff/${createdUserId}`} />;
  }

  return (
    <StaffDetailWrapper>
      <PhotoSidePart userImage={profilePictureUrl}>
        {getImageBlock()}
        <StaffInfoBlock>
          <Button
            title="Upload an Image"
            weight="700"
            round="2px"
            kind="brownLight"
            fontSize="1.2rem"
            marginBottom="spacingMinimum"
            marginTop="spacingSml"
            sizeW="widest"
            sizeH="tall"
            onClickHandler={showModalHandler}
          />
        </StaffInfoBlock>
      </PhotoSidePart>
      <PassInfoDetails>
        <StaffForm id="create-staff-user-form" onSubmit={values => handleOnSubmit(values)}>
          {errorOnSubmit && <FormErrorMsg>{errorMsg}</FormErrorMsg>}
          <PersonalInformation editMode={true} showUpdateButton={false} />
          <ContactInformation editMode={true} showUpdateButton={false} />
          <EmploymentInformation editMode={true} showUpdateButton={false} rolesOptions={rolesOptions} />
          <ButtonsBlock>
            <Button
              title="Create User"
              textColor="white"
              btnType="submit"
              borderColor="green"
              round="0"
              sizeW="wide"
              kind="green"
              marginBottom="spacingMinimum"
              fontSize="1.2rem"
              sizeH="tall"
              weight="500"
              loading={loading}
            />
          </ButtonsBlock>
        </StaffForm>
      </PassInfoDetails>
      {photoUploadModalOpen && (
        <>
          <UploadModal
            onSubmitModalHandler={onSubmitImageModalHandler}
            submitBtnDisabled={!isImgLoadedInEditor}
            onLoading={loading || uploading}
            onClosePhotoUploadModal={showModalHandler}
            extraActionBtn={extraActionBtnConfig}>
            <ImageCropper editorMode={editorMode} onEditorModeUpdate={updateEditorModeHandler} onPreviewImageHandler={onPreviewImageHandler} />
          </UploadModal>
        </>
      )}
    </StaffDetailWrapper>
  );
};
