import React from 'react';
import styled from 'styled-components';

// Account Theme
import { accountTheme } from 'es-themes';

const LogoSpan = styled.div`
  width: auto;
  max-height: 100%;
`;

const StyledESLogo = styled.figure`
  width: auto;
  height: auto;
  text-align: center;
  padding: 0;
`;

export const ESLogo = ({ color, size }: { color: string; size: string }): JSX.Element => {
  const EsLogo = accountTheme['entabeni'].EsLogo;
  return (
    <StyledESLogo>
      <LogoSpan>
        <EsLogo color={color || 'white'} size={size || '60px'} />
      </LogoSpan>
    </StyledESLogo>
  );
};
