import React from 'react';
import { asField } from 'informed';
import ReactSelect from 'react-select';

// Components
import styled from 'styled-components';
import { Icon } from 'es-components';

interface SelectFieldContainerI {
  optionsPosition?: string;
}

const SelectFieldContainer = styled.div<SelectFieldContainerI>`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  position: relative;
  margin-top: 15px;

  .react-select__menu {
    margin-top: ${props => (props.optionsPosition === 'over' ? '-365px' : '')}
`;

const FieldErrorMsg = styled.small`
  display: block;
  position: absolute;
  right: 0;
  bottom: -12px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: ${props => props.theme.red};
  padding: 3px 10px;
  color: ${props => props.theme.white};
  z-index: 2;
`;

const ClearBtn = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => props.theme.grey};
  right: 2px;
  top: 2px;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    color: ${props => props.theme.greyBlackShade};
  }
`;

interface SelectOptionI {
  value: string;
  label?: string;
}

interface SelectProps {
  fieldState?: any;
  fieldApi?: any;
  id?: string;
  label?: string;
  customError?: string;
  value?: any;
  showClearButton?: boolean;
  optionsPosition?: string;
  onChange?: (val?: any) => void;
  onBlur?: (val?: any) => void;
  forwardedRef?: any;
  borderRadius?: string;
  multi?: boolean;
  options?: SelectOptionI[];
}

export const SelectInput = asField(
  ({ id, label, customError, fieldState, fieldApi, value, showClearButton, optionsPosition, ...props }: SelectProps): JSX.Element => {
    const { setValue, setTouched } = fieldApi;
    const errorMsg = customError || fieldState.error;
    const { onChange, onBlur, forwardedRef, borderRadius, multi, ...rest } = props;

    if (value && !multi) {
      value = props.options.find(opt => opt.value === value);
    } else if (multi && value) {
      const selectorValue = [];
      props.options.forEach(option => {
        if (value.indexOf(option.value) !== -1) {
          selectorValue.push(option);
        }
      });
      value = selectorValue;
    } else {
      value = fieldState.value;
    }

    const onChangeValue = e => {
      setValue(e);
      if (onChange) {
        onChange(e);
      }
    };

    const onClearSelect = () => {
      onChangeValue('');
    };

    let showClearBtn = value !== '' && showClearButton;

    const sharedStyles = {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'center',
      height: 'auto',
      minHeight: '40px'
    };

    const standardStyles = {
      control: styles => ({
        ...styles,
        ...sharedStyles,
        background: 'transparent',
        marginBottom: '0.7rem',
        border: '2px solid #7B7B7B',
        borderRadius: borderRadius != null ? borderRadius : '10px'
      })
    };

    return (
      <SelectFieldContainer optionsPosition={optionsPosition}>
        <ReactSelect
          {...rest}
          isMulti={multi}
          classNamePrefix="react-select"
          styles={standardStyles}
          ref={forwardedRef}
          value={!value && value !== 0 ? '' : value}
          onChange={onChangeValue}
          onBlur={e => {
            setTouched();
            if (onBlur) {
              onBlur(e);
            }
          }}
          fieldstate={fieldState}
          err={errorMsg}
        />
        {showClearBtn && (
          <ClearBtn onClick={onClearSelect}>
            <Icon name="IoMdCloseCircleOutline" size="1.6em" />
          </ClearBtn>
        )}
        {errorMsg ? <FieldErrorMsg>{errorMsg}</FieldErrorMsg> : null}
      </SelectFieldContainer>
    );
  }
);
