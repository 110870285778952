import styled, { css } from 'styled-components';

interface TypeSpacingStyleI {
  margin?: string;
  padding?: string;
}

// Set the button kind (Theme color value)
const typeSpacingStyle = css<TypeSpacingStyleI>`
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`;

interface HI {
  size?: string;
  light?: boolean;
}

//Font and Weights Roboto:300,400,500,700
export const H1 = styled.h1<HI>`
  font-size: ${props => props.size || '1.6rem'};
  font-weight: ${props => (props.light ? '300' : '700')};
  color: ${props => props.theme[props.color] || props.theme.greyBlack};

  ${typeSpacingStyle}
`;

interface H2I extends HI {
  height?: string;
  marginLeft?: string;
  marginBottom?: string;
  marginTop?: string;
}

export const H2 = styled.h2<H2I>`
  font-size: ${props => props.size || '1.5rem'};
  font-weight: ${props => (props.light ? '400' : '800')};
  color: ${props => props.theme[props.color] || props.theme.greyBlack};
  margin-left: ${props => props.theme[props.marginLeft] || props.marginLeft || 0};
  margin-bottom: ${props => props.theme[props.marginBottom] || props.marginBottom || 0};
  margin-top: ${props => props.theme[props.marginTop] || props.marginTop || 0};
  overflow: hidden;
  height: ${props => props.height || 'auto'};
`;

export const H3 = styled.h3<HI>`
  font-size: ${props => props.size || '1.4rem'};
  font-weight: 500;
  color: ${props => props.theme[props.color] || props.theme.greyBlack};

  ${typeSpacingStyle}
`;

export const H4 = styled.h4<HI>`
  font-size: ${props => props.size || '1.3rem'};
  font-weight: 500;
  color: ${props => props.theme[props.color] || props.theme.greyBlack};
`;

export const H5 = styled.h5<HI>`
  font-size: ${props => props.size || '1.2rem'};
  font-weight: ${props => (props.light ? '400' : '500')};
  color: ${props => props.theme[props.color] || props.theme.greyBlack};

  ${typeSpacingStyle}
`;

export const H6 = styled.h6<HI>`
  font-size: ${props => props.size || '1.2rem'};
  font-weight: 300;
  color: ${props => props.theme[props.color] || props.theme.greyBlack};

  ${typeSpacingStyle}
`;

interface ParI {
  size?: string;
  float?: string;
  weight?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
}

export const Par = styled.p<ParI>`
  font-size: ${props => props.size || '1rem'};
  float: ${props => (props.float ? props.float : 'none')};
  font-weight: ${props => (props.weight ? props.weight : '400')};
  color: ${props => props.theme[props.color] || props.theme.greyBlack};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  margin-left: ${props => props.theme[props.marginLeft] || props.marginLeft || 0};
  margin-top: ${props => props.theme[props.marginTop] || 0};
`;

interface BoldI {
  bolder?: string;
  marginLeft?: string;
  marginRight?: string;
}

export const Bold = styled.strong<BoldI>`
  font-size: inherit;
  font-weight: ${props => (props.bolder ? 700 : 500)};
  color: ${props => props.theme[props.color] || 'inherit'};
  margin-left: ${props => props.marginLeft || 0};
  margin-right: ${props => props.marginRight || 0};
`;

export const Small = styled.small`
  font-size: 0.7rem;
  font-weight: 300;
  color: ${props => props.theme[props.color] || props.theme.greyBlack};

  ${typeSpacingStyle}
`;

export const HorizontalLine = styled.div`
  padding: 6px 4px;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.greyShade};
`;
