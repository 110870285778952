import React from 'react';

// Icons
import * as Ionic from 'react-icons/io';
import * as FontAwesome from 'react-icons/fa';

const IconList = {
  ...Ionic,
  ...FontAwesome
};

// Icons
export const Icon = ({ name, size }: { name: string; size: string }): JSX.Element => {
  const IconName = IconList[name];

  return <IconName className="icon" size={size} />;
};
