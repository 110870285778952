import React from 'react';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import { Icon } from 'es-components';
import { asField } from 'informed';

interface sharedFieldStyleI {
  border?: string;
  borderRadius?: string;
  color?: string;
}

const sharedFieldStyle = css<sharedFieldStyleI>`
  display: flex;
  align-items: center;
  outline: 0;
  z-index: 1;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 400;
  border: ${props => props.border || '2px solid'};
  border-radius: ${props => props.borderRadius || '10px'};
  transition: border 0.2s, font 0.2s;
  position: relative;
  color: ${props => props.theme.greyDark};
  border-color: ${props => props.theme[props.color] || props.theme.greyDark};
`;

const FieldMaskedInput = styled(MaskedInput)`
  width: 100%;
  height: 40px;
  padding: ${props => (props.fieldstate.value ? '8px 50px 0 15px' : '0 50px 0 15px')};

  ${sharedFieldStyle}

  &:hover {
    transition: all 0.4s;
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  border-right: ${props => props.err && `2px solid ${props.theme.red}`} !important;
  margin-top: ${props => (props.interBlock ? '15px' : 0)};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const TextFieldContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-height: 40px;
  position: relative;
  margin-bottom: 0.7em;
`;

interface FieldLabelI {
  activeInput?: boolean;
  interBlock?: boolean;
}

const FieldLabel = styled.label<FieldLabelI>`
  top: 0;
  left: 15px;
  right: 0;
  bottom: ${props => (props.activeInput ? '25px' : 0)};
  z-index: 0;
  margin-top: ${props => (props.interBlock ? '15px' : 0)};
  color: ${props => props.theme.greyDark};
  font-size: ${props => (props.activeInput ? '0.6rem' : '1rem')};
  position: absolute;
  display: flex;
  align-items: center;
`;

const FieldErrorMsg = styled.small`
  display: block;
  position: absolute;
  right: 0;
  bottom: -12px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: ${props => props.theme.red};
  padding: 3px 10px;
  color: ${props => props.theme.white};
  z-index: 2;
`;

const ClearBtn = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => props.theme.grey};
  right: 2px;
  top: 17px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    color: ${props => props.theme.greyBlackShade};
  }
`;

interface MaskedTextInputProps {
  fieldState?: any;
  fieldApi?: any;
  id?: string;
  label?: string;
  border?: string;
  customError?: string;
  value?: any;
  interBlock?: boolean;
  showClearButton?: boolean;
  onChange?: (val?: any) => void;
  onBlur?: (val?: any) => void;
  forwardedRef?: any;
}

export const MaskedTextInput = asField(
  ({ id, label, customError, fieldState, fieldApi, value, showClearButton, ...props }: MaskedTextInputProps): JSX.Element => {
    const { setValue, setTouched } = fieldApi;
    const errorMsg = customError || fieldState.error;
    const { onChange, onBlur, forwardedRef, ...rest } = props;

    if (value && value !== 'Invalid Date') {
      fieldState.maskedValue = value;
      fieldState.value = value;
    } else {
      value = fieldState.value;
    }

    const onChangeValue = e => {
      if (e !== '') {
        setValue(e.target.value);
      } else {
        setValue(e);
      }

      if (onChange) {
        onChange(e);
      }
    };

    const onClear = () => {
      onChangeValue('');
    };

    const showClearBtn = value !== '' && showClearButton;

    return (
      <TextFieldContainer className="fieldContainer">
        <FieldLabel interBlock htmlFor={id} activeInput={fieldState.value}>
          {label}
        </FieldLabel>
        <FieldMaskedInput
          {...rest}
          id={id}
          interBlock
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          ref={forwardedRef}
          value={!value && value !== 0 ? '' : value}
          onChange={onChangeValue}
          onBlur={e => {
            setTouched();
            if (onBlur) {
              onBlur(e);
            }
          }}
          fieldstate={fieldState}
          err={errorMsg}
        />
        {showClearBtn && (
          <ClearBtn onClick={onClear}>
            <Icon name="IoMdCloseCircleOutline" size="1.6em" />
          </ClearBtn>
        )}
        {errorMsg ? <FieldErrorMsg>{errorMsg}</FieldErrorMsg> : null}
      </TextFieldContainer>
    );
  }
);
