export function validateAll(validationFuncs) {
  return (value, values) => {
    let result = undefined;
    validationFuncs.forEach(func => {
      if (!result) {
        if (values && Object.keys(values).length === 0) {
          result = func(value);
        } else {
          result = func(value, values);
        }
      }
    });
    return result;
  };
}

export function validateRequired(value) {
  const wysiwygValueUnix = '<p></p>\n';
  const wysiwygValueWin = '<p></p>\n\r';
  const emptyRTEValue = '<p><br></p>';

  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === false ||
    value === emptyRTEValue ||
    value === wysiwygValueUnix ||
    value === wysiwygValueWin
  ) {
    return 'This value is required.';
  }
  return undefined;
}

export function validateEmail(value: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(value)) {
    return 'Please enter a valid email address.';
  }
  return undefined;
}

export function validateContainsEmail(value) {
  const re = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/im;
  if (re.test(value)) {
    return 'This cannot contain an email address.';
  }
  return undefined;
}

export function validateContainsPhoneNumber(value) {
  const re = /[0](\d{9})|([0](\d{2})( |-)((\d{3}))( |-)(\d{4}))|[0](\d{2})( |-)(\d{7})/im;
  if (re.test(value)) {
    return 'This cannot contain a phone number.';
  }
  return undefined;
}

export function validateContainsURL(value) {
  const re = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/im;
  if (re.test(value)) {
    return 'This cannot contain a url.';
  }
  return undefined;
}

export function validateBranchCode(value) {
  const re = /^[0-9]{5,6}$/;
  if (re.test(value)) {
    return undefined;
  }
  return 'This is not a valid Branch Code.';
}

export function validatePhoneNumber(value: string) {
  const re = /^\d{10}$/;
  if (re.test(value)) {
    return undefined;
  }
  return 'This is not a valid Phone number.';
}

export function validateSamePassword(pw1, pw2) {
  if (pw1 && pw2 && pw1 === pw2) {
    return undefined;
  }
  return 'Passwords do not match.';
}

export function validatePassword(value: string) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
  if (!value) {
    return 'Please enter a password';
  }
  if (value.length < 8) {
    return 'Please make sure your password is at least 8 characters and contains at least one uppercase letter, one lowercase letter, and 1 number.';
  }
  if (re.test(value)) {
    return undefined;
  }
  return 'Please make sure your password is at least 8 characters and contains at least one uppercase letter, one lowercase letter, and 1 number.';
}

export function validateMinChars(number: number) {
  if (!number) number = 0;
  return value => {
    if (value.length < number) {
      return `This must be at least ${number} characters long.`;
    }
    return undefined;
  };
}

export function validateMaxChars(number: number) {
  if (!number) number = 0;
  return value => {
    if (value.length > number) {
      return `This must be less than ${number} characters long.`;
    }
    return undefined;
  };
}
