import React, { useState } from 'react';
import styled from 'styled-components';

import { DayPickerCalendar, MaskedTextInput } from 'es-components';

const InputWrapper = styled.div``;

interface DatePickerInputProps {
  id: string;
  field: string;
  label: string;
  border: string;
  onClick: (id: string) => void;
  onCalendarClick: (value: string, id: string) => void;
}

interface DatePickerInputState {
  showDatePicker?: boolean;
  valueForCalendar?: string;
  value?: string;
}

export class DatePickerInput extends React.Component<DatePickerInputProps, DatePickerInputState> {
  state = {
    showDatePicker: false,
    valueForCalendar: null,
    value: ''
  };

  hideDatePickerCalendar = () => {
    this.setState({ showDatePicker: false });
  };

  showDatePickerCalendar = () => {
    this.setState({ showDatePicker: true }, () => this.props.onClick(this.props.id));
  };

  onCalendarClick = date => {
    const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
    const monthIndex = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const year = date.getFullYear();

    const dateFormat = window.localStorage.getItem('dateFormat') || 'MM/DD/YYYY';
    let separator = '/';
    if (dateFormat.indexOf('-') != -1) {
      separator = '-';
    } else if (dateFormat.indexOf('/') != -1) {
      separator = '/';
    }

    let dateValue = '';

    const formatArray = dateFormat.split(separator);
    formatArray.forEach((element, index) => {
      switch (element) {
        case 'MM':
          dateValue += monthIndex;
          break;
        case 'DD':
          dateValue += day;
          break;
        case 'YYYY':
          dateValue += year;
          break;
      }

      if (index < 2) {
        dateValue += separator;
      }
    });

    this.setState({ showDatePicker: false, value: dateValue, valueForCalendar: date }, () => this.props.onCalendarClick(dateValue, this.props.id));
  };

  render() {
    const { id, field, label, border } = this.props;

    return (
      <InputWrapper>
        <MaskedTextInput
          value={this.state.value}
          id={id}
          field={field}
          label={label}
          autoComplete="off"
          border={border}
          interBlock
          onClick={this.showDatePickerCalendar}
        />
        {this.state.showDatePicker && <DayPickerCalendar id={`calendar_${id}`} selectedDays={[this.state.valueForCalendar]} onClick={this.onCalendarClick} />}
      </InputWrapper>
    );
  }
}
