import React, { useState } from 'react';

// Components
import styled from 'styled-components';
import { H2, StaffForm, Button, MaskedFromToInput, GenerationModal } from 'es-components';
import { Report, reportSaveDateFormat } from 'es-libs';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

interface GenerateReportArgumentsI {
  fromDate?: string;
  toDate?: string;
}

interface CreateReportVars {
  arguments: GenerateReportArgumentsI;
}

interface CreateReportData {
  ticketWindow: { createReport: Report };
}

const GENERATE_REPORT_MUTATION = gql`
  mutation CreateReport($arguments: TicketWindowArgumentsInput) {
    ticketWindow {
      createReport(reportType: redemption, arguments: $arguments) {
        id
        created
        userName
        fileUrl
      }
    }
  }
`;

const FilterControlsWrapper = styled.div`
  display: flex !important;
  box-sizing: border-box  
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%
  margin-bottom: 15px;
  height: 40px;
`;

const SelectorWrapper = styled.div`
  width: 295px;
`;

export const HeaderReportGenerator = ({ onReportAdd }: { onReportAdd: () => void }): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  //mutation
  const [createReport, { loading }] = useMutation<CreateReportData, CreateReportVars>(GENERATE_REPORT_MUTATION);

  const changeDateRangeInput = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const handleOnSubmit = () => {
    setShowModal(true);
    let newValues = {
      arguments: { fromDate: reportSaveDateFormat(fromDate), toDate: reportSaveDateFormat(toDate) }
    };

    createReport({ variables: { ...newValues } }).then(({ data }) => {
      if (data) {
        onReportAdd();
      }
    });
  };

  return (
    <StaffForm id="generate-report-form" onSubmit={handleOnSubmit}>
      <H2 light color="greyBlackShade" size="1rem" marginTop="spacingTiny">
        Report generator arguments:
      </H2>
      <FilterControlsWrapper>
        <SelectorWrapper>
          <MaskedFromToInput id="date" onCheckButtonAvailability={changeDateRangeInput} />
        </SelectorWrapper>
        <Button
          title="Generate Report"
          weight="500"
          sizeW="wide"
          round="10px"
          disabled={fromDate == null || toDate == null}
          fontSize="1.3rem"
          textColor="white"
          btnType="submit"
          kind="brownLight"
          borderColor="brownLight"
        />
      </FilterControlsWrapper>
      {showModal && <GenerationModal onDownloadButtonClick={() => setShowModal(false)} loading={loading} />}
    </StaffForm>
  );
};
