import React from 'react';

// Components
import { HeaderContentWrapper, ContentWrapper, CreateStaffBody, StaffDetailsHeader, Error } from 'es-components';
import { auth, Role } from 'es-libs';
import { PageLoader } from 'es-containers';

// Graph QL
import { gql } from 'apollo-boost/lib/index';
import { useQuery } from '@apollo/react-hooks';

interface RolesData {
  ticketWindow: { allRoles: Role[] };
}

const GET_A_ROLES_QUERY = gql`
  {
    ticketWindow {
      allRoles {
        id
        name
      }
    }
  }
`;

export const CreateStaffUser = () => {
  const { data, loading, error } = useQuery<RolesData>(GET_A_ROLES_QUERY);

  const currentUserRole = auth.getPermissions().canWriteStaffUsers;

  const renderBody = () => {
    if (loading) {
      return <PageLoader backgroundColor="white" show={loading} />;
    }

    if (error || data === null) {
      return <Error />;
    }

    if (!loading && data != null) {
      const { allRoles } = data.ticketWindow;
      const rolesOptions = allRoles.map(option => {
        return { value: option.id, label: option.name };
      });
      return <CreateStaffBody rolesOptions={rolesOptions} />;
    }
  };

  if (!currentUserRole) {
    return 'Page not found';
  }

  return (
    <ContentWrapper backgroundColor="white">
      <HeaderContentWrapper height="65px" backgroundColor="greyLightTint" paddingTop="spacingTiny" paddingBottom="spacingTiny" paddingLeft="0" paddingRight="0">
        <StaffDetailsHeader createMode />
      </HeaderContentWrapper>
      {renderBody()}
    </ContentWrapper>
  );
};
