import React from 'react';
import { debounce } from 'debounce';

// Components
import styled from 'styled-components';
import { H5, SearchTextInput } from 'es-components';

interface GridHeaderWrapperI {
  marginBottom?: string;
}

const GridHeaderWrapper = styled.div<GridHeaderWrapperI>`
  box-sizing: border-box
  color: ${props => props.theme.greyShade};
  background-color: ${props => props.theme.white};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  margin-top: ${props => props.theme[props.marginBottom] || 0};
  padding-left: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
`;

export const ListTableHeader = ({ onSearch, value, searchTitle }: { onSearch: (value: string) => void; value: string; searchTitle: string }): JSX.Element => {
  const onChangeSearch = value => {
    if (onSearch) {
      onSearch(value);
    }
  };

  return (
    <GridHeaderWrapper marginBottom="spacingTiny">
      <H5 color="greyDark" size="1.4rem" />
      <SearchTextInput
        id="searchInput"
        value={value}
        onClear={onChangeSearch}
        onChangeSearchInput={debounce(onChangeSearch, 1000)}
        color="greyDark"
        searchTitle={searchTitle}
      />
    </GridHeaderWrapper>
  );
};
