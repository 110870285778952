import styled from 'styled-components';

export const InformationColumnBlock = styled.div`
  min-width: 250px;
  width: 100%;
  padding-right: 5px;
`;

export const InformationRowBlock = styled.div`Contact Information

  display: flex !important;
  width: 100%;
  box-sizing: border-box
  color: ${props => props.theme.greyShade};
  padding: 0 0.7em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%
  margin-bottom: 5px;
`;

interface InformationEditBlockI {
  minHeight?: string;
  border?: string;
}

export const InformationEditBlock = styled.div<InformationEditBlockI>`
  width: auto;
  height: auto;
  margin-bottom: 15px;
  background-color: ${props => props.theme.greyLightTint};
  padding-bottom: 15px;
  min-height: ${props => props.minHeight || 'auto'};
  box-shadow: ${props => props.theme.boxShadow};
  border: ${props => props.border || props.theme.StdBorder};

  .secondInputRow {
    margin-top: -10px;
    margin-bottom: -5px;
  }
`;

export const ButtonBlock = styled.div`
  box-sizing: border-box;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
`;

export const EditIconConteiner = styled.div`
  cursor: pointer;
`;
