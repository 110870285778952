import React from 'react';
import { withCookies } from 'react-cookie';
import styled from 'styled-components';

// Components
import { HeaderNavigator } from 'es-containers';

const SiteHeader = styled.header`
  width: 100%;
  height: 90px;
  grid-row: 1/2;
  z-index: 2001;
`;

const SiteHeaderSection = styled.section`
  width: 100%;
  height: 85px;
  border-bottom: 1px solid ${props => props.theme.primaryShade};
  background-color: ${props => props.theme.primaryShade};
  position: relative;
  display: flex;
  align-items: center;
`;

const getActiveRoute = (pathName: string): string => {
  if (pathName.indexOf('guest') !== -1) {
    return 'guest';
  } else if (pathName.indexOf('staff') !== -1) {
    return 'staff';
  } else if (pathName.indexOf('report') !== -1) {
    return 'report';
  }
};

const MainHeader = ({ path }: { path: { pathname: string } }): JSX.Element => {
  const active = getActiveRoute(path.pathname);
  return (
    <SiteHeader>
      <SiteHeaderSection>
        <HeaderNavigator active={active} />
      </SiteHeaderSection>
    </SiteHeader>
  );
};

export default MainHeader;
