import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import styled from 'styled-components';

import { ESLogo } from 'es-components';

const LogoContainer = styled.strong`
  font-weight: 500;
  color: ${props => props.theme.primary};
  padding: 1rem 0.5rem;
`;

export const AuthLogoBanner = (): JSX.Element => (
  <LogoContainer>
    <ESLogo color="white" size="250px" />
  </LogoContainer>
);
