import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Components
import { HeaderContentWrapper, ContentWrapper, StaffDetailsBody, StaffDetailsHeader, Error } from 'es-components';
import { Guest, Role } from 'es-libs';
import { PageLoader } from 'es-containers';

// Graph QL
import { gql } from 'apollo-boost/lib/index';
import { useQuery } from '@apollo/react-hooks';

interface GetStaffVars {
  id: string;
}

interface GetStaffData {
  ticketWindow: { staffUser: Guest };
}

const GET_STAFF_QUERY = gql`
  query StaffUser($id: String!) {
    ticketWindow {
      staffUser(id: $id) {
        id
        email
        lastName
        fullName
        firstName
        phone
        lastLogin
        dateOfBirth
        avatar
        profilePictureUrl
        sex
        employmentTypeIds
        roleIds
      }
    }
  }
`;

interface RolesData {
  ticketWindow: { allRoles: Role[] };
}

const GET_A_ROLES_QUERY = gql`
  {
    ticketWindow {
      allRoles {
        id
        name
      }
    }
  }
`;

export const StaffDetails = ({ match }: RouteComponentProps): JSX.Element => {
  //queries
  const { data: staffUserData, loading: staffUserLoading, error: staffUserError } = useQuery<GetStaffData, GetStaffVars>(GET_STAFF_QUERY, {
    variables: { id: match.params['id'] }
  });

  const { data: rolesData, loading: rolesLoading } = useQuery<RolesData>(GET_A_ROLES_QUERY);

  const renderBody = (): JSX.Element => {
    if (staffUserLoading || rolesLoading) {
      return <PageLoader backgroundColor="white" show={staffUserLoading || rolesLoading} />;
    }

    if (staffUserError) {
      return <Error />;
    }

    if (!staffUserLoading && !rolesLoading && staffUserData != null && rolesData != null) {
      const { staffUser } = staffUserData.ticketWindow;
      const avatar = staffUser.profilePictureUrl || staffUser.avatar;
      const { allRoles } = rolesData.ticketWindow;
      const rolesOptions = allRoles.map(option => {
        return { value: option.id, label: option.name };
      });

      return (
        <React.Fragment>
          <HeaderContentWrapper
            height="65px"
            backgroundColor="greyLightTint"
            paddingTop="spacingTiny"
            paddingBottom="spacingTiny"
            paddingLeft="0"
            paddingRight="0">
            <StaffDetailsHeader name={staffUser.fullName} listPage={match.params['page']} listFilter={match.params['filter']} />
          </HeaderContentWrapper>
          <StaffDetailsBody
            id={staffUser.id}
            image={avatar}
            fullName={staffUser.fullName}
            lastName={staffUser.lastName}
            firstName={staffUser.firstName}
            sex={staffUser.sex}
            phone={staffUser.phone}
            dateOfBirth={staffUser.dateOfBirth}
            email={staffUser.email}
            employmentTypeIds={staffUser.employmentTypeIds}
            roleIds={staffUser.roleIds}
            rolesOptions={rolesOptions}
          />
        </React.Fragment>
      );
    }
  };

  return <ContentWrapper backgroundColor="white">{renderBody()}</ContentWrapper>;
};
