import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePickerInput } from 'es-components';

const InputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${props => props.theme.greyDark};
  transition: border 0.2s, font 0.2s;
`;

const SmallHorizontalLine = styled.div`
  width: 33px;
  margin-top: 4px;
  margin-left: -35px;
  border-top: 3px solid black;
`;

interface MaskedFromToInputProps {
  id?: string;
  onCheckButtonAvailability: (fromDateValue, toDateValue) => void;
}

interface MaskedFromToInputState {
  fromDateValue?: string;
  toDateValue?: string;
}

export class MaskedFromToInput extends React.Component<MaskedFromToInputProps, MaskedFromToInputState> {
  state = {
    fromDateValue: null,
    toDateValue: null
  };

  toDate = null;
  fromDate = null;

  checkButtonAvailability = () => {
    const { fromDateValue, toDateValue } = this.state;
    if (fromDateValue != null && toDateValue != null) {
      this.props.onCheckButtonAvailability(fromDateValue, toDateValue);
    } else {
      this.props.onCheckButtonAvailability(fromDateValue, toDateValue);
    }
  };

  handleInputClick = id => {
    if (id === 'fromDate') {
      this.toDate.hideDatePickerCalendar();
    }
    if (id === 'toDate') {
      this.fromDate.hideDatePickerCalendar();
    }
  };

  onChangeDates = (val, id) => {
    if (id === 'fromDate') {
      this.setState({ fromDateValue: val }, this.checkButtonAvailability);
    }
    if (id === 'toDate') {
      this.setState({ toDateValue: val }, this.checkButtonAvailability);
    }
  };

  render() {
    return (
      <InputWrapper>
        <DatePickerInput
          ref={ref => (this.fromDate = ref)}
          onCalendarClick={this.onChangeDates}
          onClick={this.handleInputClick}
          id="fromDate"
          field="fromDate"
          label="Date from"
          border="none"
        />
        <SmallHorizontalLine />
        <DatePickerInput
          ref={ref => (this.toDate = ref)}
          onCalendarClick={this.onChangeDates}
          onClick={this.handleInputClick}
          id="toDate"
          field="toDate"
          label="Date To"
          border="none"
        />
      </InputWrapper>
    );
  }
}
