import React, { useRef, useState } from 'react';
import PictureEditor from '@entabeni-systems/react-picture-editor';

// Components
import styled from 'styled-components';
import ImageDragDrop from './ImageDragDrop';

const ImageEditorContainer = styled.div`
  background-color: #efefef;
  height: auto;
  position: relative;
  width: 100%;
`;

const ImageCropper = ({
  editorMode,
  onEditorModeUpdate,
  onPreviewImageHandler
}: {
  editorMode: boolean;
  onEditorModeUpdate: (e: boolean) => void;
  onPreviewImageHandler: (isImgLoadedInEditor: boolean, imageData: PictureEditor) => void;
}) => {
  const [dropImgSrc, dropImgSrcHandler] = useState(null);
  const [imgHeight, setImgHeight] = useState(160);
  const [imgWidth, setImgWidth] = useState(160);
  let pictureEditorRef = useRef();

  const addImageHandler = acceptedFile => {
    if (acceptedFile && acceptedFile.length > 0) {
      let fr = new FileReader();
      let img = new Image();
      img.onload = () => {
        setImgHeight(img.height);
        setImgWidth(img.width);
        dropImgSrcHandler(acceptedFile[0]);
        onEditorModeUpdate(true);
        fr = null;
        img = null;
      };
      fr.onload = () => {
        img.src = fr.result.toString();
      };
      fr.readAsDataURL(acceptedFile[0]);
    }
  };

  const processImageData = (): PictureEditor => {
    return pictureEditorRef != undefined && pictureEditorRef.current != undefined && pictureEditorRef.current;
  };

  return !editorMode ? (
    <ImageDragDrop onDropHandler={imgFile => addImageHandler(imgFile)} />
  ) : (
    <ImageEditorContainer>
      <PictureEditor
        ref={pictureEditorRef}
        imgSrc={dropImgSrc}
        isSrcAFile={true}
        loadingCB={() => onPreviewImageHandler(true, processImageData())}
        editorSizing={{
          height: `${imgHeight < 480 ? imgHeight : 480}px`,
          width: `${imgWidth < 640 ? imgWidth : 640}px`
        }}
        imgSizing={{
          height: `${imgHeight < 480 ? imgHeight : 480}px`,
          width: `${imgWidth < 640 ? imgWidth : 640}px`
        }}
      />
    </ImageEditorContainer>
  );
};

export default ImageCropper;
