import React from 'react';
import Dropzone from 'react-dropzone';

// Components
import styled from 'styled-components';
import { Icon } from 'es-components';

const ImageUploadContainer = styled.section`
  width: 100%;
  max-width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 30px;
  background-color: ${props => props.theme.greyLight};
`;
const ImageUploadDragArea = styled.div`
  width: 100%;
  min-height: 200px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.7em 0;
  color: ${props => props.theme.greyDark};
  font-weight: 400;
  font-size: 1.5rem;
  border-top: 2px dotted ${props => props.theme.greyShade};
  border-right: 2px dotted ${props => props.theme.greyShade};
  border-left: 2px dotted ${props => props.theme.greyShade};
  transition: all 0.4s ease-in-out;

  small {
    font-size: 12px;
    margin-top: 10px;
    padding: 0 10px;
    text-align: center;
  }
`;

const ImageUploadIcon = styled.span`
  width: 50%;
  height: auto;
  color: ${props => props.theme.greyShade};
  transition: all 0.4s ease-in-out;
`;

const ImageUploadButton = styled.div`
  height: 40px;
  width: 100px%;
  cursor: pointer;
  background-color: ${props => props.theme.brownLight};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
  transition: all 0.4s ease-in-out;
`;

const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(',').map(item => {
  return item.trim();
});

const ImageDragDrop = ({ onDropHandler }: { onDropHandler: (imageData: File) => void }): JSX.Element => {
  const verifyFile = files => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;

      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert('Invalid file type. Only valid image files will be accepted.');
        return false;
      }
      return true;
    }
  };

  const handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        onDropHandler(files);
      }
    }
  };

  return (
    <Dropzone onDrop={handleOnDrop} multiple={false} accept={acceptedFileTypes}>
      {({ getRootProps, getInputProps }) => {
        return (
          <ImageUploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <ImageUploadDragArea>
              <ImageUploadIcon color="tertiary">
                <Icon name="IoIosCloudUpload" size="100%" />
              </ImageUploadIcon>
              <p>
                Drag and Drop <br />a file to upload
              </p>
              <small>Accepted files: jpg, jpeg, png and gif.</small>
            </ImageUploadDragArea>
            <ImageUploadButton>Browse files</ImageUploadButton>
          </ImageUploadContainer>
        );
      }}
    </Dropzone>
  );
};

export default ImageDragDrop;
