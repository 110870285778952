import React from 'react';

// Components
import styled from 'styled-components';
import { Icon } from 'es-components';

const IconConteiner = styled.div`
  margin-left: 45px;
  margin-top: 20px;
`;

interface PhotoPartI {
  hideBottomBorder?: boolean;
}

const PhotoPart = styled.div<PhotoPartI>`
  height: 250px;
  width: 316px;
  border-radius: 12px 0 0 0;
  color: ${props => props.theme.grey};
  border-bottom: ${props => (props.hideBottomBorder ? '' : '2px solid')};
  border-color: ${props => props.theme.grey};
`;

export const EmptyProfilePhoto = ({ size, hideBottomBorder }: { size?: string; hideBottomBorder?: boolean }) => (
  <PhotoPart hideBottomBorder={hideBottomBorder}>
    <IconConteiner>
      <Icon name="IoMdImage" size={size ? size : '14em'} />
    </IconConteiner>
  </PhotoPart>
);
