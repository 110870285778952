import React from 'react';

// Components
import styled from 'styled-components';

const PagerWrapper = styled.div`
  display: flex;
  height: auto;
  margin: 5px 0;
  flex-direction: row;
`;

interface PageButtonI {
  active?: boolean;
}

const PageButton = styled.div<PageButtonI>`
  margin: 2px;
  width: 25px;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5em;
  height: 25px;
  border: 1px solid;
  border-color: ${props => (props.active ? props.theme.green : props.theme.greyDark)};
  cursor: pointer;
  color: ${props => (props.active ? props.theme.white : props.theme.greyDark)};
  background-color: ${props => (props.active ? props.theme.green : '#f8f8f8')};
  border-radius: 4px;
  &:hover {
    background-color: ${props => props.theme.greyShade};
  }
`;

interface PagerProps {
  onPageChanged: (val: number) => void;
  totalRecords: number;
  recordsOnPage: number;
  current: number;
}

export const Pager = ({ onPageChanged, totalRecords, recordsOnPage, current }: PagerProps): JSX.Element => {
  const getNextPageElement = (pagesCount, current): JSX.Element => {
    if (pagesCount !== current && pagesCount > 5) {
      const nextPageLabel = '>>';
      return <PageButton onClick={() => this.onPageClick(current + 1)}>{nextPageLabel}</PageButton>;
    }
  };

  const getPrevPageElement = (pagesCount, current): JSX.Element => {
    if (current !== 1 && pagesCount > 5) {
      const prevPageLabel = '<<';
      return <PageButton onClick={() => this.onPageClick(current - 1)}>{prevPageLabel}</PageButton>;
    }
  };

  const pagesCount = Math.floor(totalRecords / recordsOnPage) + 1;
  let pageObjects = [];
  if (pagesCount > 1) {
    const maxI = pagesCount - (current + 4) > 0 ? current + 4 : pagesCount;
    let begin = maxI - current === 4 ? current : pagesCount - 4;
    if (begin <= 0) {
      begin = 1;
    }
    for (let i = begin; i <= maxI; i++) {
      pageObjects.push({ value: i, label: i });
    }
  }
  return (
    <PagerWrapper>
      {getPrevPageElement(pagesCount, current)}
      {pageObjects.map(page => (
        <PageButton key={page.value} active={current === page.value} onClick={() => onPageChanged(page.value)}>
          {page.label}
        </PageButton>
      ))}
      {getNextPageElement(pagesCount, current)}
    </PagerWrapper>
  );
};
