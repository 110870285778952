import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Pages
import {
  CreateStaffUser,
  Login,
  Logout,
  ForgotPassword,
  PasswordRecovery,
  NotFound,
  GuestList,
  GuestDetails,
  RedemptionReports,
  StaffList,
  StaffDetails
} from 'es-pages';

// Auth
import { auth } from 'es-libs';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
};

// set PrivateRoutes for some pages
export default (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route key="login" path="/login" component={Login} />
    <Route key="logout" path="/logout" component={Logout} />
    <Route key="resetpassword" path="/resetPassword/:token?" component={PasswordRecovery} />
    <Route key="forgotpassword" path="/forgotpassword" component={ForgotPassword} />
    <PrivateRoute exact path="/guest_list/:page?/:filter?" component={GuestList} />
    <PrivateRoute key="staff-list" path="/staff_list/:page?/:filter?" component={StaffList} />
    <PrivateRoute key="reports" path="/reports" component={RedemptionReports} />
    <PrivateRoute key="guest" path="/guest/:id/:page?/:filter?" component={GuestDetails} />
    <PrivateRoute key="staff" path="/staff/:id/:page?/:filter?" component={StaffDetails} />
    <PrivateRoute key="create-staff" path="/create_staff" component={CreateStaffUser} />
    <Route key="not-found" component={NotFound} />
  </Switch>
);
