import React from 'react';

// Components
import { HeaderContentWrapper, ContentWrapper, GuestsWithRedemptionsView, GuestDetailsHeader, Error } from 'es-components';
import { PageLoader } from 'es-containers';
import { RouteComponentProps } from 'react-router-dom';
import { Guest } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

interface GetGuestVars {
  id: string;
}

interface GetGuestData {
  ticketWindow: { guest: Guest };
}

const GET_GUEST_QUERY = gql`
  query Guest($id: String!) {
    ticketWindow {
      guest(id: $id) {
        id
        email
        phone
        fullName
        dateOfBirth
        avatar
        profilePictureUrl
        created
        active
        accessRecordId
        accessRecords {
          productJson
        }
        accessPermissionId
        canResendWaiver
        purchaseDate
        redemptionsAvailable {
          noRedemptionReason
          redemptionsAvailable {
            blackoutDiscount
            discount
            full
          }
          redemptionAvailable
        }
        family {
          id
          email
          phone
          fullName
          dateOfBirth
          avatar
          profilePictureUrl
          created
          canResendWaiver
          active
          accessRecords {
            productJson
          }
          accessRecordId
          accessPermissionId
          redemptionsAvailable {
            noRedemptionReason
            redemptionsAvailable {
              blackoutDiscount
              discount
              full
            }
            redemptionAvailable
          }
          purchaseDate
          redemptions {
            id
            redemptionLevel
            redemptionDate
          }
        }
        redemptions {
          id
          redemptionLevel
          redemptionDate
        }
      }
    }
  }
`;

export const GuestDetails = ({ match }: RouteComponentProps): JSX.Element => {
  //query
  const { data, loading, error } = useQuery<GetGuestData, GetGuestVars>(GET_GUEST_QUERY, {
    variables: { id: match.params['id'] }
  });

  const renderBody = () => {
    if (loading) {
      return <PageLoader backgroundColor="white" show={loading} />;
    }

    if (error || data === null) {
      return <Error />;
    }

    if (!loading && data != null) {
      const { guest } = data.ticketWindow;
      const guestsArray = [guest];
      guestsArray.push(...guest.family);

      return (
        <React.Fragment>
          <HeaderContentWrapper
            backgroundColor="greyLightTint"
            paddingTop="spacingTiny"
            paddingBottom="spacingTiny"
            height="65px"
            paddingLeft="0"
            paddingRight="0">
            <GuestDetailsHeader name={guest.fullName} listPage={match.params['page']} listFilter={match.params['filter']} />
          </HeaderContentWrapper>
          <GuestsWithRedemptionsView guests={guestsArray} />
        </React.Fragment>
      );
    }
  };

  return <ContentWrapper backgroundColor="white">{renderBody()}</ContentWrapper>;
};
