import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import PictureEditor from '@entabeni-systems/react-picture-editor';

// Components
import styled from 'styled-components';
import {
  FormErrorMsg,
  Icon,
  ImageCropper,
  PersonalInformation,
  EmploymentInformation,
  ContactInformation,
  UploadModal,
  StaffForm,
  EmptyProfilePhoto
} from 'es-components';
import { RoleOptionI } from './staffInfoComponents/StaffInfoInterface';

// libs
import { auth, Guest } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const PhotoSidePart = styled.div`
  margin-left: 10px;
  height: 324px;
  width: 320px;
  color: ${props => props.theme.greyShade};
  background-color: ${props => props.theme.white};
  border: ${props => props.theme.StdBorder};
  border-radius: 14px 0 0 0;
  box-shadow: ${props => props.theme.boxShadow};
`;

const UserImage = styled.div<UserImageI>`
  background-image: url(${props => props.userImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
  width: 316px;
  border-radius: 12px 0 0 0;
`;

const StaffDetailWrapper = styled.div`
  display: flex;
  min-height: 50px;
  margin-top: 10px;
  flex-direction: row;
  background-color: ${props => props.theme.white};
  padding: 0.8rem 1rem;
  border-radius: 14px 0 0 0;
`;

const PassInfoDetails = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const EditProfilePhotoIconContainer = styled.div<UserImageI>`
  width: 45px;
  height: 45px;
  display: inline;
  float: right;
  padding-top: 5px;
  border-radius: 0 0 0 10px;
  margin-top: ${props => (props.userImage ? '-320px' : `-270px`)};
  cursor: pointer;
  color: ${props => props.theme.greyBlackShade};
  background-color: ${props => props.theme.grey};
  opacity: 0.7;
`;

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin: -3px -3px 5px 3px;
`;

interface UserImageI {
  userImage: string;
}

interface StaffUpdateVars {
  id: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  sex?: string;
  phone?: string;
  profilePictureUrl?: string;
  employmentTypeIds?: string[];
  roleIds?: string[];
}

interface StaffUpdateData {
  ticketWindow: { updateStaffUser: Guest };
}

const STAFF_USER_UPDATE_MUTATION = gql`
  mutation updateStaffUser(
    $id: String!
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $sex: String
    $phone: String
    $profilePictureUrl: String
    $employmentTypeIds: [String]
    $roleIds: [String]
  ) {
    ticketWindow {
      updateStaffUser(
        id: $id
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        dateOfBirth: $dateOfBirth
        sex: $sex
        profilePictureUrl: $profilePictureUrl
        employmentTypeIds: $employmentTypeIds
        roleIds: $roleIds
      ) {
        id
        objectID
        email
        sex
        avatar
        lastLoginUnix
        dateOfBirth
        phone
        lastName
        fullName
        firstName
        employmentTypeIds
        roleIds
        profilePictureUrl
      }
    }
  }
`;

interface StaffDetailsBodyProps {
  id: string;
  image: string;
  fullName: string;
  lastName: string;
  firstName: string;
  sex: string;
  phone: string;
  dateOfBirth: string;
  email: string;
  employmentTypeIds: string[];
  roleIds: string[];
  rolesOptions: RoleOptionI[];
}

export const StaffDetailsBody = ({
  id,
  image,
  fullName,
  firstName,
  lastName,
  sex,
  phone,
  dateOfBirth,
  email,
  employmentTypeIds,
  roleIds,
  rolesOptions
}: StaffDetailsBodyProps): JSX.Element => {
  const [editorMode, setEditorMode] = useState(false);
  const [photoUploadModalOpen, setPhotoUploadModalOpen] = useState(false);
  const [personalInformationEdit, setPersonalInformationEdit] = useState(false);
  const [contactInformationEdit, setContactInformationEdit] = useState(false);
  const [employmentInformationEdit, setEmploymentInformationEdit] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userImage, setUserImage] = useState(image);
  const [loaderPostfix, setLoaderPostfix] = useState(Math.random() * 10000);
  const [isImgLoadedInEditor, setIsImgLoadedInEditor] = useState(false);
  const [currentImageData, setCurrentImageData] = useState(undefined);

  const { addToast } = useToasts();

  //mutation
  const [updateStaffUser, { loading }] = useMutation<StaffUpdateData, StaffUpdateVars>(STAFF_USER_UPDATE_MUTATION);

  const handleOnSubmit = (values: any, blockToClose: string) => {
    switch (blockToClose) {
      case 'personalInformation':
        setPersonalInformationEdit(!personalInformationEdit);
        break;
      case 'contactInformation':
        setContactInformationEdit(!contactInformationEdit);
        break;
      case 'employmentInformationEdit':
        setEmploymentInformationEdit(!employmentInformationEdit);
        break;
    }
    if (values.sex) {
      values.sex = values.sex.value;
    }
    if (values.employmentTypeIds) {
      const val = [];
      values.employmentTypeIds.forEach(v => {
        val.push(v.value);
      });
      values.employmentTypeIds = val;
    }
    if (values.roleIds) {
      const val = [];
      values.roleIds.forEach(v => {
        val.push(v.value);
      });
      values.roleIds = val;
    }
    updateStaffUser({ variables: { id: id, ...values } }).catch(error => {
      const newError = error.graphQLErrors ? error.graphQLErrors.map(x => x.message) : '';
      setErrorOnSubmit(true);
      setErrorMsg(newError);
    });
  };

  const getImageBlock = (): JSX.Element => {
    if (userImage) {
      return <UserImage userImage={userImage + '?' + loaderPostfix} />;
    } else {
      return <EmptyProfilePhoto hideBottomBorder />;
    }
  };

  const showModalHandler = () => {
    setPhotoUploadModalOpen(!photoUploadModalOpen);
    if (photoUploadModalOpen) {
      setEditorMode(false);
    }
  };

  const uploadFile = async file => {
    setUploading(true);
    const urlData = await fetch(`${auth.getBaseUrl()}/signUrl?userId=${id}&uploadType=avatar`, {
      method: 'GET',
      mode: 'cors',
      headers: { Authorization: auth.getToken(), 'Content-Type': 'application/json', 'X-Key-Inflection': 'camel' }
    });
    urlData.json().then(async json => {
      const awsImage = await fetch(json.signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file ? file.type : '' },
        body: file
      });
      const savedImage = await updateStaffUser({
        variables: { id: id, profilePictureUrl: awsImage.url.split('?')[0] }
      });
      if (savedImage) {
        setUploading(false);
        setUserImage(savedImage.data.ticketWindow.updateStaffUser.profilePictureUrl);
        setLoaderPostfix(Math.random() * 10000);
        showModalHandler();
        addToast('Image uploaded successfully.', { appearance: 'success', autoDismiss: true });
      }
    });
  };

  const extraActionBtnConfig = {
    clickHandler: () => setEditorMode(false),
    show: editorMode,
    styling: { marginRight: 'auto' },
    title: 'Choose another file'
  };

  const updateEditorModeHandler = isEnabled => {
    setEditorMode(isEnabled);
  };

  const onPreviewImageHandler = (isImgLoadedInEditor: boolean, imageData: PictureEditor) => {
    setIsImgLoadedInEditor(isImgLoadedInEditor);
    if (currentImageData == null) {
      setCurrentImageData(imageData);
    }
  };

  const onSubmitImageModalHandler = () => {
    const image = currentImageData.getImageFromCanvas();
    if (image) {
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          const fileName = 'avatar';
          const fileType = `${fileName}.jpg`;
          blob['name'] = fileType;

          let imageData = new File([blob], fileType, {
            type: blob.type
          });

          uploadFile(imageData);
        });
    }
  };

  return (
    <StaffDetailWrapper>
      <PhotoSidePart>
        {getImageBlock()}
        <EditProfilePhotoIconContainer onClick={showModalHandler} userImage={userImage}>
          <IconContainer>
            <Icon name="IoMdCreate" size="2.4em" />
          </IconContainer>
        </EditProfilePhotoIconContainer>
      </PhotoSidePart>
      <PassInfoDetails>
        <StaffForm
          initialValues={{ lastName, firstName, dateOfBirth, sex }}
          id="edit-staff-user-personal-info"
          onSubmit={values => handleOnSubmit(values, 'personalInformation')}>
          {errorOnSubmit && <FormErrorMsg>{errorMsg}</FormErrorMsg>}
          <PersonalInformation
            lastName={lastName}
            firstName={firstName}
            fullName={fullName}
            dateOfBirth={dateOfBirth}
            sex={sex}
            editMode={personalInformationEdit}
            onEditModeChange={() => setPersonalInformationEdit(!personalInformationEdit)}
            showUpdateButton={true}
          />
        </StaffForm>
        <StaffForm initialValues={{ email, phone }} id="edit-staff-user-contact-info" onSubmit={values => handleOnSubmit(values, 'contactInformation')}>
          <ContactInformation
            onEditModeChange={() => setContactInformationEdit(!contactInformationEdit)}
            email={email}
            phone={phone}
            editMode={contactInformationEdit}
            showUpdateButton={true}
          />
        </StaffForm>
        <StaffForm id="edit-staff-user-employment-info" onSubmit={values => handleOnSubmit(values, 'employmentInformationEdit')}>
          <EmploymentInformation
            roleIds={roleIds}
            rolesOptions={rolesOptions}
            editMode={employmentInformationEdit}
            onEditModeChange={() => setEmploymentInformationEdit(!employmentInformationEdit)}
            showUpdateButton={true}
          />
        </StaffForm>
      </PassInfoDetails>
      {photoUploadModalOpen && (
        <>
          <UploadModal
            onSubmitModalHandler={onSubmitImageModalHandler}
            submitBtnDisabled={!isImgLoadedInEditor}
            onLoading={loading || uploading}
            onClosePhotoUploadModal={showModalHandler}
            extraActionBtn={extraActionBtnConfig}>
            <ImageCropper editorMode={editorMode} onEditorModeUpdate={updateEditorModeHandler} onPreviewImageHandler={onPreviewImageHandler} />
          </UploadModal>
        </>
      )}
    </StaffDetailWrapper>
  );
};
