import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import styled from 'styled-components';

const LinkLabel = styled.strong`
  font-weight: 500;
  color: ${props => props.theme.white};
  margin-left: 0.5rem;
  padding: 0 0 0.25rem;
`;

interface ForgotPasswordI {
  backgroundColor?: string;
}

const ForgotPassword = styled(Link)<ForgotPasswordI>`
  width: 100%;
  text-align: center;
  height: 50px;
  position: absolute;
  font-weight: 400;
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.transparent};
  color: ${props => props.theme.greyShade};

  &:hover strong {
    border-bottom: 1px solid ${props => props.theme.greyShade};
  }
`;

interface AuthFooterProps {
  linkTo: string;
  linkPar?: string;
  linkLabel: string;
  backgroundColor?: string;
}

export const AuthFooter = ({ linkTo, linkPar, linkLabel, backgroundColor }: AuthFooterProps): JSX.Element => (
  <ForgotPassword backgroundColor={backgroundColor} to={linkTo}>
    {linkPar ? linkPar : null}
    <LinkLabel>{linkLabel}</LinkLabel>
  </ForgotPassword>
);
