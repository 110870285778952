import React from 'react';
import styled from 'styled-components';

// Component
import { Modal } from '../Modal';
import { Par, Button } from 'es-components';
import { displayDateFormat } from 'es-libs';

interface BlankModalContainerI {
  hasTitle?: boolean;
}

const BlankModalContainer = styled.div<BlankModalContainerI>`
  min-height: 100%;
  width: 500px;
  height: 350px;
  position: relative;
  padding-top: ${props => (props.hasTitle ? '60px' : 0)};
  border-radius: 20px;
  padding-bottom: 60px;
`;

const ConfirmationModalWrapper = styled.div`
  width: 450px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -225px;

  b {
    color: black;
  }
`;

const ButtonsBlock = styled.div`
  margin-left: 55px;
  display: flex;
  flex-direction: row;
  margin-top: 80px;
`;

interface RedemptionConfirmModalProps {
  onConfirmButtonClick: () => void;
  onCancelButtonClick: () => void;
  loading: boolean;
  fullName: string;
  redemptionLevel: string;
}

export const RedemptionConfirmModal = ({
  onConfirmButtonClick,
  onCancelButtonClick,
  loading,
  fullName,
  redemptionLevel
}: RedemptionConfirmModalProps): JSX.Element => {
  const getModalBody = () => {
    return (
      <ConfirmationModalWrapper>
        <Par size="1.5rem" marginBottom="spacingLrg" marginLeft="5Waiver resend confirmation0px">
          Redemption creation confirmation
        </Par>
        <Par size="1.1rem" marginTop="spacingSml" marginBottom="spacingSml" marginLeft="50px">
          Are you sure <b>{fullName}</b> would like to do a <b>{redemptionLevel}</b> for today, {displayDateFormat(Date.now())}?
        </Par>
        <ButtonsBlock>
          <Button
            title="Cancel"
            weight="500"
            textColor="brownLight"
            fontSize="1.8rem"
            loading={loading}
            borderColor="brownLight"
            onClickHandler={onCancelButtonClick}
          />
          <Button
            title="Confirm"
            marginLeft="20px"
            weight="500"
            fontSize="1.8rem"
            textColor="white"
            loading={loading}
            kind="brownLight"
            borderColor="brownLight"
            onClickHandler={onConfirmButtonClick}
          />
        </ButtonsBlock>
      </ConfirmationModalWrapper>
    );
  };

  return (
    <Modal>
      <BlankModalContainer>{getModalBody()}</BlankModalContainer>
    </Modal>
  );
};
