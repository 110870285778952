import React, { useState } from 'react';

import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';

// Components
import styled from 'styled-components';
import { H2, RedemptionRecord, GuestDetailsNotify, EmptyProfilePhoto, Button, WaiverConfirmModal, RedemptionConfirmModal } from 'es-components';
import { displayDateFormat, auth, Redemption, RedemptionsAvailable } from 'es-libs';
import { RedemptionsAvailableList } from '../../libs/interfaces';

interface CreateRedemptionVars {
  accessRecordId: string;
  accessPermissionId: string;
  userId: string;
  guestId: string;
}

interface CreateRedemptionData {
  ticketWindow: { createRedemption: Redemption };
}

const CREATE_REDEMPTION = gql`
  mutation CreateRedemption($accessRecordId: String!, $accessPermissionId: String!, $userId: String!, $guestId: String!) {
    ticketWindow {
      createRedemption(accessRecordId: $accessRecordId, accessPermissionId: $accessPermissionId, userId: $userId, guestId: $guestId) {
        id
        receiptUrl
        accessRecordId
        redemptionLevel
        accessPermissionId
        userId
        resortId
        guestId
        guest {
          id
          redemptionsAvailable {
            noRedemptionReason
            redemptionsAvailable {
              blackoutDiscount
              discount
              full
            }
            redemptionAvailable
          }
        }
        resort {
          id
          name
          level
          validationMessage1
          validationMessage2
          validationMessage3
          blackoutDateId
        }
      }
    }
  }
`;

interface ResendWaiverVars {
  id: string;
}

interface ResendWaiverData {
  ticketWindow: { allGuestCompletedWaivers: { id: string } };
}

const RESEND_WAIVER_QUERY = gql`
  query AllGuestCompletedWaivers($id: String!) {
    ticketWindow {
      allGuestCompletedWaivers(id: $id) {
        id
      }
    }
  }
`;

interface PhotoSidePartI {
  border?: string;
}

const PhotoSidePart = styled.div<PhotoSidePartI>`
  margin-left: 10px;
  height: auto;
  width: 320px;
  color: ${props => props.theme.greyShade};
  background-color: ${props => props.theme.white};
  border: ${props => props.border || props.theme.StdBorder};
  border-radius: 14px 0 0 0;
  box-shadow: ${props => props.theme.boxShadow};
`;

interface UserImageI {
  userImage?: string;
}

const UserImage = styled.div<UserImageI>`
  background-image: url(${props => props.userImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
  width: 316px;
  border-radius: 12px 0 0 0;
  border-bottom: 3px solid;
  border-color: ${props => {
    switch (props.color) {
      case 'Active':
        return props.theme.success;
      case 'Expired':
        return props.theme.error;
      case 'InActive':
        return props.theme.info;
      default:
        return props.theme.info;
    }
  }};
`;

const HorizontalLine = styled.div`
  padding: 6px 4px;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.greyShade};
`;

const GuestInfoBlock = styled.div`
  padding: 15px;
`;

const GuestDetailWrapper = styled.div`
  min-height: 50px;
  margin-top: 10px;
  background-color: ${props => props.theme.white};
  padding: 0.8rem 1rem;
  border-radius: 14px 0 0 0;
`;

const PassInfoDetails = styled.div`
  margin-left: 10px;
  width: 320px;
`;

interface GuestDetailsBodyProps {
  redemptionsInfo: Redemption[];
  userImage: string;
  name: string;
  phone: string;
  dateOfBirth: string;
  email: string;
  canResendWaiver: boolean;
  guestId: string;
  purchaseDate: string;
  redemptionAvailable: string;
  status: string;
  productName: string;
  accessRecordId: string;
  accessPermissionId: string;
  noRedemptionReason: string;
  notificationText?: string;
  redemptionsAvailable: RedemptionsAvailableList;
}

export const GuestDetailsBody = ({
  redemptionsInfo,
  userImage,
  name,
  phone,
  dateOfBirth,
  email,
  canResendWaiver,
  guestId,
  purchaseDate,
  redemptionAvailable,
  status,
  accessRecordId,
  accessPermissionId,
  noRedemptionReason,
  productName,
  notificationText,
  redemptionsAvailable
}: GuestDetailsBodyProps): JSX.Element => {
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [waiverConfirmationModal, setWaiverConfirmationModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [redemptionsData, setRedemptionsData] = useState(redemptionsInfo);

  const { addToast } = useToasts();

  //mutation
  const [createRedemption, { loading }] = useMutation<CreateRedemptionData, CreateRedemptionVars>(CREATE_REDEMPTION);

  //lazy query
  const [resendWaiver, { data: resendWaiverData, loading: resendWaiverLoading }] = useLazyQuery<ResendWaiverData, ResendWaiverVars>(RESEND_WAIVER_QUERY);

  if (resendWaiverData != null && !resendWaiverLoading && waiverConfirmationModal) {
    addToast('Waiver was successfully sent.', { appearance: 'success', autoDismiss: true });
    setWaiverConfirmationModal(false);
  }

  const getDetailPart = () => {
    if (status != null && notifyOpen) {
      return <GuestDetailsNotify status={status} text={notificationText} onCloseNotify={() => setNotifyOpen(false)} />;
    }
  };

  const getImageBlock = (userImage, status) => {
    if (userImage) {
      return <UserImage color={status} userImage={userImage} />;
    } else {
      return <EmptyProfilePhoto />;
    }
  };

  const onConfirmButtonClick = () => {
    const userId = auth.getAuthenticatedUserId();
    createRedemption({ variables: { guestId, userId, accessRecordId, accessPermissionId } })
      .then(({ data }) => {
        redemptionsData.push(data.ticketWindow.createRedemption);
        setRedemptionsData(redemptionsData);
        setConfirmModalOpen(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getButtonColor = () => {
    switch (redemptionAvailable) {
      case 'full':
        return 'green';
      case 'discount':
        return 'orange';
      case 'blackoutDiscount':
        return 'yellow';
      default:
        return 'red';
    }
  };

  const getButtonTextColor = () => {
    switch (redemptionAvailable) {
      case 'full':
        return 'white';
      case 'discount':
        return 'white';
      case 'blackoutDiscount':
        return 'black';
      default:
        return 'white';
    }
  };

  const getButtonTitle = () => {
    switch (redemptionAvailable) {
      case 'full':
        return 'Full Redemption';
      case 'discount':
        return 'Discount Redemption';
      case 'blackoutDiscount':
        return 'Blackout Redemption';
      default:
        return 'No Redemption Available';
    }
  };

  const getRedemptionsPart = () => {
    const resortRedemptions = [];
    redemptionsData.forEach(redemption => {
      const resortName = redemption.resortName || (redemption.resort && redemption.resort.name) || 'test';
      if (resortRedemptions[resortName]) {
        resortRedemptions[resortName].push(redemption);
      } else {
        resortRedemptions[resortName] = [redemption];
      }
    });

    return (
      <>
        {Object.keys(resortRedemptions).map(resortName => (
          <div key={resortName}>
            <H2 light marginTop="spacingSml">
              Redemptions for {name} at {resortName}
            </H2>
            <HorizontalLine />
            {resortRedemptions[resortName].map(redemption => (
              <RedemptionRecord
                key={redemption.id}
                title={redemption.redemptionLevel}
                date={redemption.redemptionDate ? displayDateFormat(redemption.redemptionDate) : ''}
              />
            ))}
          </div>
        ))}
      </>
    );
  };

  const renderGuestInfoBody = () => {
    return (
      <>
        <GuestInfoBlock>
          <H2 light color="greyDark" size="1.6rem" marginBottom="spacingMinimum">
            {name}
          </H2>
          <H2 light color="greyDark" size="0.9rem" marginBottom="spacingMinimum">
            {phone}
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {email}
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {`Date of birth: ${dateOfBirth ? displayDateFormat(dateOfBirth) : ''}`}
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {`Purchase date: ${purchaseDate ? displayDateFormat(purchaseDate) : ''}`}
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {`Product name: ${productName}`}
          </H2>
        </GuestInfoBlock>
        <GuestInfoBlock>
          <H2 light color="green" size="1.6rem" marginBottom="spacingMinimum">
            Available Redemptions:
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {`Full: ${redemptionsAvailable ? redemptionsAvailable.full : null}`}
          </H2>
          <H2 light color="greyDark" size="0.9rem">
            {`Discount: ${redemptionsAvailable ? redemptionsAvailable.discount : null}`}
          </H2>
          {redemptionsAvailable?.blackoutDiscount !== null && (
            <H2 light color="greyDark" size="0.9rem">
              {`Blackout Discount: ${redemptionsAvailable ? redemptionsAvailable.blackoutDiscount : null}`}
            </H2>
          )}
        </GuestInfoBlock>
      </>
    );
  };

  const onWaiverConfirmButtonClick = () => {
    resendWaiver({
      variables: {
        id: guestId
      }
    });
  };

  const { canReadRedemptions, canWriteRedemptions } = auth.getPermissions();
  const buttonColor = getButtonColor();
  const buttonTextColor = getButtonTextColor();
  const buttonTitle = getButtonTitle();

  return (
    <GuestDetailWrapper>
      <PhotoSidePart>
        {getImageBlock(userImage, status)}
        {renderGuestInfoBody()}
        {canReadRedemptions && (
          <Button
            title={buttonTitle}
            textColor={buttonTextColor}
            borderColor={buttonColor}
            round="0"
            customWidth="316px"
            kind={buttonColor}
            onClickHandler={() => setConfirmModalOpen(true)}
            fontSize="1.2rem"
            disabled={!canWriteRedemptions || redemptionAvailable === null}
            disabledOverideColor={true}
            sizeH="tall"
            weight="500"
            loading={loading}
          />
        )}
      </PhotoSidePart>
      <GuestInfoBlock>
        <H2 light color="red" size="1rem">
          {noRedemptionReason}
        </H2>
        {canResendWaiver && (
          <H2 light size="1rem" onClick={() => setWaiverConfirmationModal(true)}>
            <a style={{ textDecoration: 'underline', cursor: 'pointer' }}>Resend Waiver</a>
          </H2>
        )}
      </GuestInfoBlock>
      {canReadRedemptions && (
        <PassInfoDetails>
          {getDetailPart()}
          {getRedemptionsPart()}
        </PassInfoDetails>
      )}
      {confirmModalOpen && (
        <RedemptionConfirmModal
          fullName={name}
          loading={loading}
          redemptionLevel={buttonTitle}
          onCancelButtonClick={() => setConfirmModalOpen(false)}
          onConfirmButtonClick={onConfirmButtonClick}
        />
      )}
      {waiverConfirmationModal && (
        <WaiverConfirmModal fullName={name} onCancelButtonClick={() => setWaiverConfirmationModal(false)} onConfirmButtonClick={onWaiverConfirmButtonClick} />
      )}
    </GuestDetailWrapper>
  );
};
