import React, { useState } from 'react';

// Components
import {
  H2,
  Icon,
  HorizontalLine,
  Button,
  InformationColumnBlock,
  InformationRowBlock,
  InformationEditBlock,
  ButtonBlock,
  EditIconConteiner,
  SelectInput
} from 'es-components';
import { auth } from 'es-libs';
import { RoleOptionI, StaffInformationProps } from './StaffInfoInterface';

interface EmploymentInformationProps extends StaffInformationProps {
  rolesOptions?: RoleOptionI[];
  roleIds?: string[];
}

export const EmploymentInformation = ({ rolesOptions, roleIds, onEditModeChange, showUpdateButton, editMode }: EmploymentInformationProps): JSX.Element => {
  const [roleIdsValue, setRoleIdsValue] = useState(roleIds);

  const getRolesString = (): string => {
    let result = '';
    rolesOptions.forEach(option => {
      if (roleIds.indexOf(option.value) !== -1) {
        if (result.length > 0) {
          result += ', ';
        }
        result += option.label;
      }
    });

    return result;
  };

  const getEditIcon = (): JSX.Element => {
    const haveAccess = auth.getPermissions().canWriteStaffUsers;
    if (haveAccess) {
      return (
        <EditIconConteiner onClick={onEditModeChange}>
          <Icon name="IoMdCreate" size="1.4em" />
        </EditIconConteiner>
      );
    } else {
      return null;
    }
  };

  const viewInformation = (): JSX.Element => {
    return (
      <>
        <InformationRowBlock>
          <H2 light marginTop="spacingSml">Employment Information</H2>
          {getEditIcon()}
        </InformationRowBlock>
        <HorizontalLine />
        <InformationRowBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Role
            </H2>
            <H2 size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {getRolesString()}
            </H2>
          </InformationColumnBlock>
          <InformationColumnBlock />
          <InformationColumnBlock />
        </InformationRowBlock>
      </>
    );
  };

  const getButtonBlock = (): JSX.Element => {
    if (showUpdateButton) {
      return (
        <ButtonBlock>
          <Button
            title="Cancel"
            textColor="grey"
            sizeH="short"
            borderColor="grey"
            marginRight="spacingTiny"
            round="10px"
            weight="500"
            fontSize="1.2rem"
            kind="white"
            marginBottom="spacingMinimum"
            onClickHandler={onEditModeChange}
          />
          <Button
            title="Update"
            textColor="white"
            borderColor="green"
            sizeH="short"
            round="10px"
            btnType="submit"
            kind="green"
            marginBottom="spacingMinimum"
            fontSize="1.2rem"
            weight="500"
          />
        </ButtonBlock>
      );
    }
  };

  const onChangeRoleIds = value => {
    const val = [];
    value.forEach(v => {
      val.push(v.value);
    });

    setRoleIdsValue(val);
  };

  const editInformation = (): JSX.Element => {
    return (
      <>
        <InformationEditBlock>
          <InformationRowBlock>
            <H2 marginTop="spacingSml">Employment Information</H2>
            {getButtonBlock()}
          </InformationRowBlock>
          <InformationRowBlock>
            <InformationColumnBlock>
              <SelectInput
                multi
                onChange={onChangeRoleIds}
                value={roleIdsValue}
                id="roleIds"
                field="roleIds"
                options={rolesOptions}
                borderRadius="0"
                placeholder="Select employee roles"
              />
            </InformationColumnBlock>
            <InformationColumnBlock />
          </InformationRowBlock>
        </InformationEditBlock>
      </>
    );
  };

  return editMode ? editInformation() : viewInformation();
};
