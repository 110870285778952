import React from 'react';
import { Link } from 'react-router-dom';

// Components
import styled from 'styled-components';
import { H2, Button } from 'es-components';

const GridHeaderWrapper = styled.div`
  height: 100px;
  box-sizing: border-box
  margin-bottom: ${props => props.theme['spacingTiny'] || 0};
  margin-top: ${props => props.theme['spacingTiny'] || 0};
  padding-left: 1.5em;
  padding-right: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%
`;

const StaffHeaderRow = styled.div`
  width: 100%;
`;

interface StaffNameWrapperI {
  createMode?: boolean;
}

const StaffNameWrapper = styled.div<StaffNameWrapperI>`
  float: left;
  margin-left: ${props => (props.createMode ? '280px' : '140px')};
  margin-top: -34px;
  font-size: 1.7rem;
  font-weight: 700;
  color: ${props => props.theme.greyDark};
`;

export const StaffDetailsHeader = ({
  name,
  createMode,
  listPage,
  listFilter
}: {
  name?: string;
  createMode?: boolean;
  listPage?: string;
  listFilter?: string;
}): JSX.Element => (
  <GridHeaderWrapper>
    <StaffHeaderRow>
      <H2 light color="greyBlackShade" size="3rem">
        {createMode ? 'Create User |' : 'Staff |'}
      </H2>
      <StaffNameWrapper createMode={createMode}>{createMode ? 'Create a new staff member' : name}</StaffNameWrapper>
    </StaffHeaderRow>
    <Link to={`/staff_list/${listPage}/${listFilter}`}>
      <Button title="Back to Staff List" weight="500" round="10px" fontSize="1.3rem" sizeW="widest" textColor="grey" borderColor="grey" />
    </Link>
  </GridHeaderWrapper>
);
