import dayjs from 'dayjs';
import moment from 'moment-timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function getDateFormat(): string {
  return window.localStorage.getItem('dateFormat') || 'MM/DD/YYYY';
}

export function saveDateFormat(date: string): string {
  const format = getDateFormat();
  return dayjs(date, format).format('YYYY/MM/DD');
}

export function reportSaveDateFormat(date: string): string {
  const format = getDateFormat();
  return dayjs(date, format).format('YYYY-MM-DD');
}

export function displayDateFormat(date: any): string {
  const formatString = getDateFormat();
  if (typeof date === 'number') {
    return dayjs(date).format(formatString);
  } else {
    let pureDate = null;
    if (date != null) {
      pureDate = date.substr(0, 10);
    }
    return moment(pureDate).format(formatString);
  }
}
