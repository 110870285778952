import React from 'react';

// Components
import styled from 'styled-components';
import { H2, Par, CardWrapper, Icon } from 'es-components';
import { displayDateFormat } from 'es-libs';

interface UserImageI {
  userImage?: string;
}

const UserImage = styled.div<UserImageI>`
  background-image: url(${props => props.userImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 72px;
  width: 72px;
  float: left;
  border-radius: 12px 0 20px 0;
  margin-right: 10px;
`;

const EmptyUserImage = styled.div`
  height: 72px;
  width: 72px;
  float: left;
  border-radius: 12px 0 20px 0;
  margin-top: -17px;
  margin-right: 25px;
  margin-left: -15px;
`;

const CardDataWrapper = styled.div`
  display: flex;
  min-height: 50px;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.theme.greyShade};
  background-color: #f8f8f8;
  padding: 0.8rem 1rem;
  border-radius: 14px 0 0 0;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.greyLight};
  }
`;

interface CardDataColumnI {
  width?: string;
}

const CardDataColumn = styled.div<CardDataColumnI>`
  width: ${props => props.width || `350px`};
  box-sizing: border-box
  color: ${props => props.theme.greyShade};
`;

const TextDataWrapper = styled.div`
  margin-top: 2px;
`;

interface IndyPassStatusI {
  light?: boolean;
}

const IndyPassStatus = styled.p<IndyPassStatusI>`
  font-size: 1.4rem;
  font-weight: ${props => (props.light ? '300' : '400')};
  color: ${props => props.theme[props.color]};
`;

interface ListElementProps {
  redemptionAvailable?: string;
  date?: string;
  position?: string;
  listType?: string;
  dateOfBirth?: string;
  userImage?: string;
  name?: string;
  email?: string;
  id?: string;
}

export const ListElement = ({ redemptionAvailable, date, position, listType, dateOfBirth, userImage, name, email, id }: ListElementProps): JSX.Element => {
  const getStatus = () => {
    switch (redemptionAvailable) {
      case 'full':
        return <IndyPassStatus color="green">Full</IndyPassStatus>;
      case 'discount':
        return <IndyPassStatus color="yellow">Discount</IndyPassStatus>;
      case 'blackoutDiscount':
        return <IndyPassStatus color="orange">Discount</IndyPassStatus>;
      default:
        return <IndyPassStatus color="red">No Redemption </IndyPassStatus>;
    }
  };

  const getListTypeColumns = () => {
    if (listType === 'guest') {
      return (
        <>
          <CardDataColumn width="250px">
            <TextDataWrapper>
              <H2 size="2rem" light color="greyDarkShade" marginBottom="spacingMinimum">
                Date of Birth
              </H2>
              <Par size="1.4rem" color="greyDark">
                {dateOfBirth ? displayDateFormat(dateOfBirth) : ''}
              </Par>
            </TextDataWrapper>
          </CardDataColumn>
          <CardDataColumn width="250px">
            <TextDataWrapper>
              <H2 size="2rem" light color="greyDarkShade" marginBottom="spacingMinimum">
                Purchase Date
              </H2>
              <Par size="1.4rem" color="greyDark">
                {date ? displayDateFormat(date) : ''}
              </Par>
            </TextDataWrapper>
          </CardDataColumn>
          <CardDataColumn width="200px">
            <TextDataWrapper>
              <H2 size="2rem" light color="greyDarkShade" marginBottom="spacingMinimum">
                Indy Pass
              </H2>
              {getStatus()}
            </TextDataWrapper>
          </CardDataColumn>
        </>
      );
    } else if (listType === 'staff') {
      return (
        <>
          <CardDataColumn width="160px">
            <TextDataWrapper>
              <H2 size="2rem" light color="greyDarkShade" marginBottom="spacingMinimum">
                Last Login
              </H2>
              <Par size="1.4rem" color="greyDark">
                {date ? displayDateFormat(date) : ''}
              </Par>
            </TextDataWrapper>
          </CardDataColumn>
          <CardDataColumn width="200px">
            <TextDataWrapper>
              <H2 size="2rem" light color="greyDarkShade" marginBottom="spacingMinimum">
                Position
              </H2>
              <Par size="1.4rem" color="greyDark">
                {position}
              </Par>
            </TextDataWrapper>
          </CardDataColumn>
        </>
      );
    }
  };

  const getImageBlock = () => {
    if (userImage) {
      return <UserImage userImage={userImage} />;
    } else {
      return (
        <EmptyUserImage>
          <Icon name="IoMdImage" size="6em" />
        </EmptyUserImage>
      );
    }
  };

  return (
    <CardWrapper marginBottom="spacingTiny">
      <CardDataWrapper id={id}>
        <CardDataColumn width="650px">
          {getImageBlock()}
          <TextDataWrapper>
            <H2 size="2rem" height="35px" light color="greyDarkShade" marginBottom="spacingMinimum">
              {name}
            </H2>
            <Par size="1.4rem" color="greyDark">
              {email}
            </Par>
          </TextDataWrapper>
        </CardDataColumn>
        {getListTypeColumns()}
      </CardDataWrapper>
    </CardWrapper>
  );
};
