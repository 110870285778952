import React from 'react';
import transition from 'styled-transition-group';

// Components
import { SpinLoader } from 'es-components';

interface PageLoaderContainerI {
  top?: string;
  left?: string;
  backgroundColor?: string;
  marginLeft?: string;
}

const PageLoaderContainer = transition.section.attrs({
  unmountOnExit: true,
  timeout: 1000
})<PageLoaderContainerI>`

  min-height: 87px;
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.transparent};
  position: absolute;
  margin-left: -100px;
  top: ${props => (props.top ? props.top : '45%')};
  left: ${props => (props.left ? props.left : '50%')};

  &:enter { opacity: 1; }
  &:enter-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
  }
  &:exit { opacity: 0.01; }
  &:exit-active {
    opacity: 1;
    transition: opacity 800ms ease-in;
  }
`;

interface PageLoaderProps {
  top?: string;
  left?: string;
  backgroundColor?: string;
  marginLeft?: string;
  show?: boolean;
  size?: string;
}

const PageLoader = ({ top, left, backgroundColor, marginLeft, show, size }: PageLoaderProps): JSX.Element => (
  <PageLoaderContainer
    unmountOnExit
    in={show}
    timeout={1000}
    direction="column"
    top={top}
    left={left}
    backgroundColor={backgroundColor}
    marginLeft={marginLeft}>
    <SpinLoader size={size ? size : '200px'} color="primary" strokeWidth="2" />
  </PageLoaderContainer>
);

export default PageLoader;
