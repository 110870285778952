import React from 'react';

// Components
import styled from 'styled-components';

// Components
import { ESLogo } from 'es-components';

interface FooterI {
  height?: string;
  margin?: string;
  padding?: string;
  border?: string;
  backgroundColor?: string;
}

export const Footer = styled.footer<FooterI>`
  width: 100%;
  height: ${props => props.height || 'auto'};
  min-height: 40px;
  display: flex;
  grid-row: 4/4;
  justify-content: center;
  align-items: center;
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  border: ${props => props.border || 0};
  background-color: ${props => props.theme[props.backgroundColor] || props.theme.primaryShade};
`;

export const Small = styled.small`
  color: ${props => props.theme[props.color] || props.theme.white};
  font-weight: 600;
  margin-left: 0.5rem;
`;

const SiteFooter = (): JSX.Element => (
  <Footer>
    <ESLogo color="white" size="30px" />
    <Small color="grey">Entabeni Systems</Small>
  </Footer>
);

export default SiteFooter;
