import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useToasts } from 'react-toast-notifications';
import { Redirect } from 'react-router-dom';

//Validations
import { auth, validateAll, validateRequired, validateEmail, Guest, Role } from 'es-libs';

// Components
import { PageWrapper, ContentWrapper, AuthWrapper, AuthFooter, LoginForm, TextInput, AuthLogoBanner, FormErrorMsg } from 'es-components';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import img from '../../images/BackgroundFour.jpeg';

interface SignInUserVars {
  email: string;
  password: string;
}

interface SignInUserData {
  authToken: string;
  success?: boolean;
  user: Guest;
}

const SIGN_IN_USER_MUTATION = gql`
  mutation SigninUser($email: String!, $password: String!) {
    ticketWindow {
      signinUser(email: $email, password: $password) {
        success
        authToken
        user {
          id
          appTypeField
          roleIds
          updated
          created
          email
          lastName
          fullName
          firstName
        }
      }
    }
  }
`;

interface GetPermissionsVars {
  id: string;
}

interface GetPermissionsData {
  ticketWindow: { role: Role };
}

const GET_PERMISSIONS = gql`
  query GetPermissions($id: String!) {
    ticketWindow {
      role(id: $id) {
        id
        name
        permissions {
          id
          section
          readPermission
          writePermission
          deletePermission
        }
      }
    }
  }
`;

const Login = ({ history, location }: RouteComponentProps): JSX.Element => {
  const { addToast } = useToasts();
  const [errorMsg, setErrorMsg] = useState('');
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const clearState = () => {
    setErrorMsg('');
    setErrorOnSubmit(false);
    setPasswordType('password');
    setPermissionsLoaded(true);
  };

  //mutation
  const [signinUser, { loading: signInUserLoading }] = useMutation<SignInUserData, SignInUserVars>(SIGN_IN_USER_MUTATION);

  // lazy query
  const [getPermissions, { data: permissionsData, loading: permissionsLoading }] = useLazyQuery<GetPermissionsData, GetPermissionsVars>(GET_PERMISSIONS);

  if (!permissionsLoaded && permissionsData != null && !permissionsLoading) {
    let from = location.state ? location.state['from'] : { pathname: '/' };
    const userPermissions = permissionsData.ticketWindow.role.permissions;
    auth.setPermissions(userPermissions);
    addToast('You have been logged in successfully.', { appearance: 'success', autoDismiss: true });
    clearState();
    history.push(from);
  }

  if (auth.isAuthenticated()) {
    return <Redirect to="/guest_list" />;
  }

  const handleOnSubmit = (values, signinUser) => {
    signinUser({ variables: { ...values } })
      .then(({ data }) => {
        if (data.ticketWindow.signinUser.success) {
          const { authToken } = data.ticketWindow.signinUser;
          const userRoleId = JSON.parse(data.ticketWindow.signinUser.user.appTypeField).ticketWindow.role;
          auth.setUserRoleId(userRoleId);
          auth.authenticate(authToken);
          getPermissions({ variables: { id: userRoleId } });
        } else {
          setErrorMsg('Email/Password combination does not match. Please try again');
          setErrorOnSubmit(true);
        }
      })
      .catch(error => {
        const newError = error.graphQLErrors ? error.graphQLErrors.map(x => x.message) : '';
        setErrorMsg(newError);
        setErrorOnSubmit(true);
      });
  };

  return (
    <PageWrapper bgImage={img}>
      <AuthWrapper direction="column" padding="spacingMed" errorMessage={errorOnSubmit}>
        <ContentWrapper backgroundColor="primaryShade" padding="1rem 1rem 0">
          <LoginForm loading={signInUserLoading} id="login-form" buttonText="Log In" onSubmitHandler={values => handleOnSubmit(values, signinUser)}>
            <AuthLogoBanner />
            {errorOnSubmit && <FormErrorMsg>{errorMsg}</FormErrorMsg>}
            <TextInput
              type="text"
              field="email"
              height="50px"
              textColor="white"
              label="Email*"
              borderRadius="0"
              icon="IoMdMail"
              backgroundColor="headerActive"
              validate={validateAll([validateRequired, validateEmail])}
              validateOnBlur
            />
            <TextInput
              type={passwordType}
              field="password"
              textColor="white"
              label="Password*"
              borderRadius="0"
              backgroundColor="headerActive"
              height="50px"
              icon="IoIosUnlock"
              validate={validateRequired}
              validateOnBlur
            />
          </LoginForm>
          <AuthFooter backgroundColor="primaryShade" linkTo="/forgotpassword" linkPar="Forgot your password?" linkLabel="Recover Password" />
        </ContentWrapper>
      </AuthWrapper>
    </PageWrapper>
  );
};

export default Login;
