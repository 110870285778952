import React from 'react';
import styled from 'styled-components';

// Component
import { Modal } from '../Modal';
import { SpinLoader, H2, Par, Button } from 'es-components';

interface BlankModalContainerI {
  hasTitle?: boolean;
}

const BlankModalContainer = styled.div<BlankModalContainerI>`
  min-height: 100%;
  width: 500px;
  height: 500px;
  position: relative;
  padding-top: ${props => (props.hasTitle ? '60px' : 0)};
  border-radius: 20px;
  padding-bottom: 60px;
`;

const LoaderContainer = styled.div`
  width: 350px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -180px 0 0 -135px;
`;

const ReadyLoaderContainer = styled.div`
  width: 450px;
  height: 250px;
  position: absolute;
  top: 55%;
  left: 50%;
  margin: -100px 0 0 -225px;
`;

const LoaderText = styled.div`
  margin-left: 92px;
  margin-top: -195px;
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
`;

interface GenerationModalProps {
  onDownloadButtonClick: () => void;
  loading: boolean;
}

export const GenerationModal = ({ onDownloadButtonClick, loading }: GenerationModalProps): JSX.Element => {
  const getModalBody = () => {
    if (loading) {
      return (
        <LoaderContainer>
          <SpinLoader size="280px" color="primary" strokeWidth="2" />
          <H2 light color="grey" size="1.2rem" marginTop="spacingLrg" marginLeft="25px">
            Busy generating the report.
          </H2>
          <LoaderText>Generating...</LoaderText>
        </LoaderContainer>
      );
    } else {
      return (
        <ReadyLoaderContainer>
          <Par size="1.8rem" marginBottom="spacingLrg" marginLeft="120px">
            Report Generated
          </Par>
          <Button
            title="Close"
            sizeW="widest"
            marginLeft="80px"
            weight="500"
            fontSize="1.8rem"
            textColor="white"
            kind="brownLight"
            borderColor="brownLight"
            onClickHandler={onDownloadButtonClick}
          />
        </ReadyLoaderContainer>
      );
    }
  };

  return (
    <Modal>
      <BlankModalContainer>{getModalBody()}</BlankModalContainer>
    </Modal>
  );
};
