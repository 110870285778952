import { css, CSSObject } from 'styled-components';

interface Breakpoints {
  phone: number;
  tablet: number;
  desktop: number;
  screen: number;
}

const breakpoints: Breakpoints = {
  phone: 580,
  tablet: 768,
  desktop: 992,
  screen: 1170
};

let media = {
  phone: null,
  tablet: null,
  desktop: null,
  screen: null
};

// Iterate through the sizes and create a media template
Object.keys(breakpoints).forEach(key => {
  media[key] = (args: CSSObject) => css`
    @media (max-width: ${breakpoints[key]}px) {
      ${css(args)}
    }
  `;
});

export default media;
