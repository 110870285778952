import React from 'react';
import styled, { css } from 'styled-components';

const ListContainer = styled.article`
  width: 100%;
`;

interface ListSectionI {
  listColWidth?: string;
}

const ListSection = styled.div<ListSectionI>`
  width: 100%;
  box-sizing: border-box;
  .listSectionHeader {
    width: 100%;
    display: grid;
    grid-template-columns: ${props => props.listColWidth};
    list-style: none;
  }
  .listSectionBody {
    width: 100%;
    ul {
      display: grid;
      grid-template-columns: ${props => props.listColWidth};
      grid-column-gap: 5px;
      list-style: none;
      li {
        list-style: none;
        padding: 0.5em;
      }
    }
  }
`;

interface ListHeaderItemI {
  align?: string;
}

const ListHeaderItem = styled.li<ListHeaderItemI>`
  font-weight: bold;
  list-style: none;
  padding: 0.5em;
  background-color: ${props => props.theme.greyLightest};
  color: ${props => props.theme.greyDarkShade};
  text-align: ${props => props.align || 'left'};
`;

interface ListItemWrapperI {
  difRowColor?: boolean;
}

export const ListItemWrapper = styled.ul<ListItemWrapperI>`
  width: 100%;
  background: ${props => props.theme.white};
  ${props =>
    props.difRowColor &&
    css`
      &:nth-child(even) {
        background-color: ${props => props.theme.greyLight};
      }
    `}
`;

interface ListItemI {
  align?: string;
}

export const ListItem = styled.li<ListItemI>`
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: ${props => props.align || 'flex-start'};
  a {
    color: ${props => props.theme.greyDarkShade};
    width: 20px;
  }
`;

interface ListHeaderElement {
  title: string;
  align?: string;
}

interface ListGridProps {
  listColWidths?: string;
  listHeaders?: ListHeaderElement[];
  children: JSX.Element[];
}

export const ListGrid = ({ listColWidths, children, listHeaders }: ListGridProps): JSX.Element => {
  return (
    <ListContainer>
      <ListSection listColWidth={!!listColWidths ? listColWidths : '1fr 1fr 1fr'}>
        <ul className="listSectionHeader">
          {listHeaders.map((header, index) => (
            <ListHeaderItem align={header.align} key={`${header.title}_${index}`}>
              {header.title}
            </ListHeaderItem>
          ))}
        </ul>
        <section className="listSectionBody">{children}</section>
      </ListSection>
    </ListContainer>
  );
};
