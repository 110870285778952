import React from 'react';
import styled, { css } from 'styled-components';

// Component
import { Modal } from '../Modal';
import { Button } from 'es-components';

interface BlankModalContainerI {
  hasTitle?: boolean;
}

const BlankModalContainer = styled.div<BlankModalContainerI>`
  border-radius: 20px;
  height: auto;
  min-height: 100%;
  min-width: 40rem;
  padding-bottom: 100px;
  padding-top: ${props => (props.hasTitle ? '60px' : 0)};
  position: relative;
  width: auto;
`;

const HeaderContainer = styled.div`
  height: 50px;
  width: 100%;
  font-size: 2rem;
  color: ${props => props.theme.greyDarkShade};
`;

const TextHeaderWrapper = styled.div`
  margin-left: 25px;
  margin-top: 12px;
`;

const BodyContainer = styled.div`
  background-color: ${props => props.theme.greyLightTint};
  height: 100%;
  width: 100%;
`;

const FooterContainer = styled.div`
  background-color: ${props => props.theme.white};
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: 16px;
  min-height: 50px;
  min-width: 100%;
  padding: 0.8rem 1rem;

  & > button {
    min-height: 50px !important;
    max-width: 240px !important;
  }
`;

const ModalContent = styled.section`
  height: auto;
  max-height: 30rem;
  max-width: 40rem;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface UploadModalProps {
  onClosePhotoUploadModal: () => void;
  onSubmitModalHandler: () => void;
  children: JSX.Element;
  onLoading: boolean;
  submitBtnDisabled?: boolean;
  extraActionBtn: any;
}

export const UploadModal = ({
  onClosePhotoUploadModal,
  onSubmitModalHandler,
  onLoading,
  extraActionBtn,
  submitBtnDisabled,
  children
}: UploadModalProps): JSX.Element => {
  const getModalBody = () => {
    return (
      <BlankModalContainer>
        <HeaderContainer>
          <TextHeaderWrapper>Upload an Image</TextHeaderWrapper>
        </HeaderContainer>
        <BodyContainer>
          <ModalContent>{children}</ModalContent>
        </BodyContainer>
        <FooterContainer>
          <ButtonsBlock>
            {extraActionBtn && extraActionBtn.clickHandler && extraActionBtn.show ? (
              <Button
                title={extraActionBtn.title}
                textColor="grey"
                borderColor="grey"
                marginRight="auto"
                round="10px"
                sizeH="short"
                weight="500"
                fontSize="1.2rem"
                kind="white"
                marginBottom="spacingMinimum"
                onClickHandler={extraActionBtn.clickHandler}
              />
            ) : null}
            <Button
              title="Cancel"
              textColor="grey"
              borderColor="grey"
              marginRight="spacingTiny"
              marginLeft="auto"
              round="10px"
              sizeH="short"
              weight="500"
              fontSize="1.2rem"
              kind="white"
              marginBottom="spacingMinimum"
              onClickHandler={onClosePhotoUploadModal}
            />
            <Button
              title="Upload"
              textColor="white"
              borderColor="brownLight"
              round="10px"
              sizeH="short"
              kind="brownLight"
              marginBottom="spacingMinimum"
              fontSize="1.2rem"
              weight="500"
              loading={onLoading}
              onClickHandler={onSubmitModalHandler}
              disabled={submitBtnDisabled}
            />
          </ButtonsBlock>
        </FooterContainer>
      </BlankModalContainer>
    );
  };

  return <Modal>{getModalBody()}</Modal>;
};
