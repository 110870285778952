import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { auth } from 'es-libs';

const Logout = () => {
  useEffect(() => {
    auth.signout();
  }, []);

  return <Redirect to="/" />;
};

export default Logout;
