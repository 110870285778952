import React from 'react';
import styled, { css, keyframes } from 'styled-components';

// Style Utils
import { flexCenterItem } from '../../utils';

// Components
import { Icon, SpinLoader } from 'es-components';

interface ButtonI {
  textColor?: string;
  borderColor?: string;
  kind?: string;
  disabledOverideColor?: boolean;
  sizeW?: string;
  sizeH?: string;
  customWidth?: string;
}

// Set the button kind (Theme color value)
const buttonKindStyle = css<ButtonI>`
  color: ${props => {
    switch (props.textColor) {
      case 'primary':
        return props.theme.primary;
      case 'secondary':
        return props.theme.secondary;
      case 'tertiary':
        return props.theme.tertiary;
      case 'grey':
        return props.theme.greyDarkShade;
      case 'red':
        return props.theme.red;
      case 'green':
        return props.theme.green;
      case 'redLight':
        return props.theme.redLight;
      case 'white':
        return props.theme.white;
      default:
        return props.theme[props.textColor] || props.textColor || props.theme.white;
    }
  }};
  border-color: ${props => {
    switch (props.borderColor) {
      case 'primary':
      case 'primaryOutline':
        return props.theme.primary;
      case 'secondary':
      case 'secondaryOutline':
        return props.theme.secondary;
      case 'tertiary':
      case 'tertiaryOutline':
        return props.theme.tertiary;
      case 'grey':
      case 'greyOutline':
        return props.theme.grey;
      case 'red':
      case 'redOutline':
        return props.theme.red;
      case 'green':
      case 'greenOutline':
        return props.theme.green;
      case 'brownLight':
        return props.theme.brownLight;
      case 'white':
        return props.theme.white;
      case 'redLight':
        return props.theme.redLight;
      case 'orange':
        return props.theme.orange;
      case 'yellow':
        return props.theme.yellow;
      default:
        return props.borderColor || props.theme.white;
    }
  }};
  background-color: ${props => {
    switch (props.kind) {
      case 'primary':
        return props.theme.primary;
      case 'secondary':
        return props.theme.secondary;
      case 'tertiary':
        return props.theme.tertiary;
      case 'grey':
        return props.theme.grey;
      case 'red':
        return props.theme.red;
      case 'green':
        return props.theme.green;
      case 'redLight':
        return props.theme.redLight;
      case 'orange':
        return props.theme.orange;
      case 'yellow':
        return props.theme.yellow;
      case 'brownLight':
        return props.theme.brownLight;
      case 'info':
        return props.theme.info;
      case 'success':
        return props.theme.success;
      case 'error':
        return props.theme.error;
      default:
        return props.theme.white;
    }
  }};
`;

const buttonHoverKindStyle = css<ButtonI>`
  color: ${props => props.theme.grey};
  background-color: ${props => {
    switch (props.kind) {
      case 'primary':
        return props.theme.primaryShade;
      case 'secondary':
        return props.theme.secondaryShade;
      case 'tertiary':
        return props.theme.tertiaryShade;
      case 'grey':
        return props.theme.grey;
      case 'green':
        return props.theme.greenShade;
      case 'red':
        return props.theme.redShade;
      case 'primaryOutline':
        return props.theme.primary;
      case 'secondaryOutline':
        return props.theme.secondary;
      case 'tertiaryOutline':
        return props.theme.tertiary;
      case 'greyOutline':
        return props.theme.greyBlackShade;
      case 'greenOutline':
        return props.theme.green;
      case 'redOutline':
        return props.theme.red;
      case 'orange':
        return props.theme.orange;
      case 'yellow':
        return props.theme.yellow;
      default:
        return props.theme.white;
    }
  }};
`;

// Set the button kind (Theme color value)
const buttonDisabledStyle = css<ButtonI>`
  color: ${props => (props.disabledOverideColor ? props.theme[props.textColor] : props.theme.greyDark)};
  border-color: ${props => (props.disabledOverideColor ? props.kind : props.theme.greyDark)};
  background-color: ${props => (props.disabledOverideColor ? props.kind : props.theme.greyLight)};
`;

const buttonLoadingDisabledStyle = css<ButtonI>`
  border-color: ${props => {
    switch (props.borderColor) {
      case 'primary':
      case 'primaryOutline':
        return props.theme.primary;
      case 'secondary':
      case 'secondaryOutline':
        return props.theme.secondary;
      case 'tertiary':
      case 'tertiaryOutline':
        return props.theme.tertiary;
      case 'grey':
      case 'greyOutline':
        return props.theme.greyBlack;
      case 'green':
      case 'greenOutline':
        return props.theme.green;
      case 'white':
        return props.theme.white;
      case 'orange':
        return props.theme.orange;
      case 'yellow':
        return props.theme.yellow;
      default:
        return props.theme.greyDark;
    }
  }};
  background-color: ${props => {
    switch (props.kind) {
      case 'brownLight':
        return props.theme.brownLight;
      case 'primary':
        return props.theme.primary;
      case 'secondary':
        return props.theme.secondary;
      case 'tertiary':
        return props.theme.tertiary;
      case 'grey':
        return props.theme.grey;
      case 'green':
        return props.theme.green;
      case 'red':
        return props.theme.red;
      case 'redLight':
        return props.theme.redLight;
      case 'orange':
        return props.theme.orange;
      case 'yellow':
        return props.theme.yellow;
      default:
        return props.theme.white;
    }
  }};
`;

// Set the button kind (Theme color value)
const buttonSizeStyle = css<ButtonI>`
  width: auto;
  min-width: ${props => {
    switch (props.sizeW) {
      case 'narrow':
        return '100px';
      case 'wide':
        return '200px';
      case 'widest':
        return '300px';
      case 'none':
        return '100%';
      default:
        return '160px';
    }
  }};

  max-width: ${props => {
    switch (props.sizeW) {
      case 'narrow':
        return '120px';
      case 'wide':
        return '250px';
      case 'none':
        return '100%';
      default:
        return '200px';
    }
  }};

  padding: ${props => {
    switch (props.sizeH) {
      case 'tall':
        return '0.7em 1em';
      case 'short':
        return '0.3em 1em';
      default:
        return '0.5em 1em';
    }
  }};

  min-height: ${props => {
    switch (props.sizeH) {
      case 'tall':
        return '50px';
      case 'short':
        return '20px';
      case 'middle':
        return '30px';
      case 'none':
        return '100%';
      default:
        return '40px';
    }
  }};
`;

const setCustomWidth = css<ButtonI>`
  width: ${props => props.customWidth};
  min-width: ${props => props.customWidth};
  max-width: ${props => props.customWidth};
`;

const btnBlastBackgroundEffect = keyframes`
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(10, 10);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(20, 20);
  }
`;

interface StdButtonI {
  marginTop?: string;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  weight?: string;
  fontSize?: string;
  round?: string;
  positionIcon?: string;
  customWidth?: string;
  loading?: boolean;
}

const StdButton = styled.button.attrs(({ btnType }: { btnType: string }) => ({
  type: btnType
}))<StdButtonI>`
  margin-bottom: ${props => props.theme[props.marginBottom] || props.marginBottom || 0};
  margin-top: ${props => props.theme[props.marginTop] || props.marginTop || 0};
  margin-left: ${props => props.theme[props.marginLeft] || props.marginLeft || 0};
  margin-right: ${props => props.theme[props.marginRight] || props.marginRight || 0};
  cursor: pointer;
  font-weight: ${props => (props.weight ? props.weight : 'normal')};;
  font-size: ${props => props.fontSize || 'inhert'};
  border-width: 2px;
  border-style: solid;
  transition: all 0.4s ease-in-out;
  font-family: ${props => props.theme.fontFamily};
  border-radius: ${props => (props.round ? props.round : 0)};
  overflow: hidden;
  position: relative;
  z-index: 0;

  ${flexCenterItem}
  flex-direction: ${props => (props.positionIcon === 'after' ? 'row-reverse' : 'row')};

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
    opacity: 0;
    border-radius: 90%;
    transform: scale(1, 1);
    background-color: rgba(100, 100, 100, 0.6);
  }

  ${buttonKindStyle}
  ${buttonSizeStyle}

  ${props => props.customWidth && setCustomWidth}

  & > .icon{
    margin: ${props => (props.positionIcon === 'after' ? '0 0 -1px 0.2em' : '0 0.2em -1px 0')};
  }

  &:disabled {
    ${props => (props.loading ? buttonLoadingDisabledStyle : buttonDisabledStyle)}
  }

  &:hover{
    ${buttonHoverKindStyle}
  }

  &:focus:not(:active)::after {
    animation: ${btnBlastBackgroundEffect} 1s linear 1;
  }
`;

const loaderColor = kind => {
  switch (kind) {
    case 'primaryOutline':
      return 'primary';
    case 'secondaryOutline':
      return 'secondary';
    case 'tertiaryOutline':
      return 'tertiary';
    case 'greyOutline':
      return 'greyBlack';
    default:
      return 'white';
  }
};

interface ButtonPros {
  title?: string;
  kind?: string;
  btnType?: string;
  borderColor?: string;
  textColor?: string;
  customWidth?: string;
  margin?: string;
  weight?: string;
  round?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  icon?: string;
  iconSize?: string;
  positionIcon?: string;
  sizeH?: string;
  sizeW?: string;
  fontSize?: string;
  onClickHandler?: () => void;
  loading?: boolean;
  disabled?: boolean;
  disabledOverideColor?: boolean;
}

export function Button({
  title,
  kind,
  btnType,
  borderColor,
  textColor,
  customWidth,
  margin,
  weight,
  round,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  icon,
  iconSize,
  positionIcon,
  sizeH,
  sizeW,
  fontSize,
  onClickHandler,
  loading,
  disabled,
  disabledOverideColor = false
}: ButtonPros): JSX.Element {
  const setLoaderColor = loaderColor(kind);

  return (
    <StdButton
      btnType={btnType || 'button'}
      kind={kind}
      margin={margin}
      round={round}
      icon={icon}
      borderColor={borderColor}
      textColor={textColor}
      positionIcon={positionIcon}
      sizeH={sizeH}
      weight={weight}
      sizeW={sizeW}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      fontSize={fontSize}
      onClick={onClickHandler}
      loading={loading}
      customWidth={customWidth}
      disabled={disabled || loading}
      disabledOverideColor={disabledOverideColor}>
      {loading ? (
        <SpinLoader size="23px" color={setLoaderColor} />
      ) : (
        <>
          {icon && <Icon name={icon} size={iconSize || '12%'} />}
          {title}
        </>
      )}
    </StdButton>
  );
}
