import React from 'react';
import styled from 'styled-components';

interface ErrorStyleI {
  textColor?: string;
  align?: string;
}

const ErrorStyle = styled.p<ErrorStyleI>`
  text-align: ${props => props.align};
  color: ${props => props.textColor};
  width: 100%;
`;

export const Error = ({ textColor, align }: { textColor?: string; align?: string }): JSX.Element => (
  <ErrorStyle textColor={textColor} align={align}>
    Something went wrong! Please refresh and try again.
  </ErrorStyle>
);
