import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

// Components
import { Button, ErrorMsg } from 'es-components';

export const BasicForm = styled(Form)`
  min-height: 300px;
  width: 100%;
  padding: 2em;
`;

interface FormGridI {
  formcols?: string;
}

const FormGrid = styled.div<FormGridI>`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.formcols}, 1fr)`};
  grid-column-gap: 1em;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5em;
  & > button {
    margin-left: 1em;
  }
`;

export default function LoginForm({ children, onSubmitHandler, buttonText, loading, ...props }) {
  return (
    <BasicForm onSubmit={values => onSubmitHandler(values)} {...props}>
      {({ formState, formApi }) => {
        const { values, errors } = formState;

        // validate same password
        if (values && values.password && values.passwordConfirmation) {
          if (values.password !== values.passwordConfirmation) {
            if (!errors.password) {
              formApi.setError('password', 'Passwords do not match.');
            }
            if (!errors.passwordConfirmation) {
              formApi.setError('passwordConfirmation', 'Passwords do not match.');
            }
          } else {
            if (values.password === values.passwordConfirmation && errors.password) {
              formApi.setError('password', null);
            }
            if (values.password === values.passwordConfirmation && errors.passwordConfirmation) {
              formApi.setError('passwordConfirmation', null);
            }
          }
        }

        return (
          <>
            <FormGrid formcols={props.formcols || 1}>{children}</FormGrid>
            <ButtonWrapper>
              <Button
                title={buttonText}
                btnType="submit"
                kind="brownLight"
                marginTop="10px"
                fontSize="25px"
                weight="500"
                borderColor="brownLight"
                textColor="white"
                sizeW="none"
                icon="IoIosLogIn"
                iconSize="2rem"
                positionIcon="after"
                loading={loading}
              />
            </ButtonWrapper>
          </>
        );
      }}
    </BasicForm>
  );
}
