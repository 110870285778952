import React from 'react';
import { useToasts } from 'react-toast-notifications';

//Validations
import { validateAll, validateRequired, validateEmail } from 'es-libs';

// Graph QL
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Components
import { PageWrapper, ContentWrapper, AuthWrapper, LoginForm, AuthLogoBanner, TextInput, AuthFooter, FormErrorMsg } from 'es-components';
import { RouteComponentProps } from 'react-router';
import img from '../../images/BackgroundFour.jpeg';

interface ResetPasswordVars {
  email: string;
}

interface ResetPasswordData {
  ticketWindow: { resetPassword: { success: string } };
}

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    ticketWindow {
      resetPassword(email: $email) {
        success
      }
    }
  }
`;

const ForgotPassword = ({ history }: RouteComponentProps): JSX.Element => {
  const { addToast } = useToasts();

  // mutations
  const [resetPassword, { loading }] = useMutation<ResetPasswordData, ResetPasswordVars>(RESET_PASSWORD_MUTATION);

  const handleOnSubmit = (values: { email: string }) => {
    resetPassword({ variables: values })
      .then(res => {
        history.push('./login');
        addToast('Password reset instruction have been sent to your email', { appearance: 'success', autoDismiss: true });
      })
      .catch(error => {
        history.push('./login');
        addToast('Password reset instruction have been sent to your email', { appearance: 'success', autoDismiss: true });
      });
  };

  return (
    <PageWrapper bgImage={img}>
      <AuthWrapper direction="column" padding="spacingMed">
        <ContentWrapper backgroundColor="primaryShade" padding="1rem 1rem 0">
          <LoginForm loading={loading} id="login-form" buttonText="Reset Password" onSubmitHandler={values => handleOnSubmit(values)}>
            <AuthLogoBanner />
            <TextInput
              type="text"
              field="email"
              height="50px"
              textColor="white"
              label="Email*"
              borderRadius="0"
              icon="IoMdMail"
              backgroundColor="headerActive"
              marginTop="10px"
              validate={validateAll([validateRequired, validateEmail])}
              validateOnBlur
            />
          </LoginForm>
          <AuthFooter linkTo="/login" linkPar="Back to" linkLabel="Login page" />
        </ContentWrapper>
      </AuthWrapper>
    </PageWrapper>
  );
};

export default ForgotPassword;
