import React from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.p`
  background-color: rgba(193, 0, 0, 0.15);
  border: 1px solid ${props => props.theme.red};
  color: ${props => props.theme.redShade};
  display: block;
  line-height: 1.4;
  margin-bottom: 1rem;
  padding: 0.5rem 1.3rem;

  > span.lb {
    display: block;
  }

  > span.lb + span.lb {
    padding-top: 0.5rem;
  }
`;

export const FormErrorMsg = ({ children }): JSX.Element => {
  const processErrorMssgs = (errorMssgs: string): JSX.Element => {
    console.log('Errors ::', errorMssgs);
    if (errorMssgs.toString) {
      errorMssgs = errorMssgs.toString();
    }
    let errorMssgSplittedArr: string[] = errorMssgs.split(/_,|\<br \/\>/.test(errorMssgs) ? '<br />' : ', ');
    let errorMssgProcArr = errorMssgSplittedArr.map((errorMssg, i) => {
      errorMssg = errorMssg.replace(/_,/g, ',');
      switch (true) {
        case /^Invalid id\: Couldn\'t find User with.*?\breset_password_token\b.*?$/.test(errorMssg):
          errorMssg = 'Guest not found. Please look in your email inbox for the most recent reset link or try resetting it again. If the problem persists get in touch with our Customer Service.';
          break;
          case /\$|NULL/.test(errorMssg):
          errorMssg = 'Something went wrong, please try again.';
          break;
      }
      return errorMssg;
    });
    return (
      <React.Fragment>
        {errorMssgProcArr.filter((error, pos, self) => {
          return self.indexOf(error) == pos;
        }).map((error, i) => <span className="lb" key={`err-${i}`}>{error}</span>)}
      </React.Fragment>
    );
  };
  return <ErrorWrapper>{processErrorMssgs(children)}</ErrorWrapper>
};
