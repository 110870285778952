import { Permission } from 'es-libs';

const permissions = {
  staffUsers: { read: true, write: true, delete: true },
  guests: { read: true },
  redemptions: { read: true, write: true },
  reports: { read: true, write: true }
};

const check = (userPermissions: Permission[], requiredPermissions: string | string[], operation = 'and'): boolean => {
  userPermissions = userPermissions || [];
  requiredPermissions = requiredPermissions || [];

  // parameter validation
  if (!Array.isArray(userPermissions)) {
    userPermissions = [userPermissions];
  }

  if (!Array.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }

  // no permissions required, access granted
  if (requiredPermissions.length === 0) {
    return true;
  }

  // user has zero permissions, access denied
  if (userPermissions.length === 0) {
    return false;
  }

  const accessArray = [];

  // for each required permission
  requiredPermissions.forEach(requiredPermission => {
    if (requiredPermission.indexOf('.') > -1) {
      const requiredPermissionParts = requiredPermission.split('.');

      if (requiredPermissionParts.length === 2) {
        const section = requiredPermissionParts[0];
        const permissionType = requiredPermissionParts[1];

        // filter user's permissions by section
        const userSectionPermissions = userPermissions.filter(permissionSection => permissionSection.section === section);

        if (userSectionPermissions.length === 0) {
          accessArray.push(false);

          // skip to next required permission
          return false;
        }

        // what's the user's score for the section?
        const userSectionRead = userSectionPermissions[0].readPermission;
        const userSectionWrite = userSectionPermissions[0].writePermission;
        const userSectionDelete = userSectionPermissions[0].deletePermission;

        if (permissions[section] && permissions[section][permissionType]) {
          let permissionToCheck = null;
          if (permissionType === 'read') {
            permissionToCheck = userSectionRead;
          } else if (permissionType === 'write') {
            permissionToCheck = userSectionWrite;
          } else if (permissionType === 'delete') {
            permissionToCheck = userSectionDelete;
          }
          if ((permissionToCheck && permissions[section][permissionType]) === permissions[section][permissionType]) {
            accessArray.push(true);
          }
        }
      } else {
        console.warn(`Invalid required permission: ${requiredPermission}`);
      }
    } else if (requiredPermission === 'instructor') {
      accessArray.push(true);
    }
  });

  if (accessArray.length > 0) {
    if (operation === 'and') {
      return accessArray.indexOf(false) === -1;
    } else if (operation === 'or') {
      return accessArray.indexOf(true) > -1;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export default check;
