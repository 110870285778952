import styled from 'styled-components';

interface AuthWrapperI {
  errorMessage?: boolean;
  direction?: string;
  padding?: string;
}

export const AuthWrapper = styled.article<AuthWrapperI>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${props => (props.errorMessage ? '-290px' : '-235px')};
  margin-left: -200px;
  width: 100%;
  height: auto;
  max-width: 400px;
  min-height: 470px;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: auto 1fr auto;
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.primaryShade};
`;
