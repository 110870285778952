import React from 'react';
import { Link } from 'react-router-dom';

// Components
import styled from 'styled-components';
import { H2, Button } from 'es-components';

interface GridHeaderWrapperI {
  marginBottom?: string;
}

const GridHeaderWrapper = styled.div<GridHeaderWrapperI>`
  width: 350px;
  box-sizing: border-box
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  margin-top: ${props => props.theme[props.marginBottom] || 0};
  padding-left: 1.5em;
  padding-right: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%
`;

const GuestHeaderRow = styled.div``;

const GuestNameWrapper = styled.div`
  float: left;
  margin-left: 155px;
  margin-top: -34px;
  font-size: 1.7rem;
  font-weight: 700;
  color: ${props => props.theme.greyDark};
`;

export const GuestDetailsHeader = ({ name, listPage, listFilter }: { name: string; listPage: number; listFilter: string }): JSX.Element => (
  <GridHeaderWrapper marginBottom="spacingTiny">
    <GuestHeaderRow>
      <H2 light color="greyBlackShade" size="3rem">
        Guest |
      </H2>
      <GuestNameWrapper>{name}</GuestNameWrapper>
    </GuestHeaderRow>
    <Link to={`/guest_list/${listPage}/${listFilter}`}>
      <Button title="Back to Guests" weight="500" round="10px" fontSize="1.3rem" textColor="grey" borderColor="grey" />
    </Link>
  </GridHeaderWrapper>
);
