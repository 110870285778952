import React from 'react';

// Components
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

interface LoaderContainerI {
  size?: string;
}

const LoaderContainer = styled.figure<LoaderContainerI>`
  display: inline-block;
  width: ${props => props.size || '30px'};
  height: ${props => props.size || '30px'};
`;

const StyledSpinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  margin: 0;
  width: 100%;
  height: 100%;

  .path {
    stroke: ${props => props.theme[props.color] || props.theme.white};
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }

  .static {
    stroke: rgba(180, 180, 180, 0.4);
    stroke-linecap: round;
  }
`;

interface SpinLoaderProps {
  color?: string;
  size?: string;
  strokeWidth?: string;
}

export const SpinLoader = ({ color, size, strokeWidth }: SpinLoaderProps): JSX.Element => {
  return (
    <LoaderContainer size={size}>
      <StyledSpinner className="spinLoader" viewBox="0 0 50 50" color={color}>
        <circle className="static" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth == null ? '4' : strokeWidth} />
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth == null ? '4' : strokeWidth} />
      </StyledSpinner>
    </LoaderContainer>
  );
};
