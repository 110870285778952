import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface ModalWrapperI {
  borderRadius?: string;
}

const ModalWrapper = styled.article<ModalWrapperI>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${props => (props.borderRadius ? props.borderRadius : `20px`)};
  display: block;
  background-color: ${props => props.theme.white};
  border: 1px solid grey;
  box-shadow: 2px 2px 2px rgba(100, 100, 100, 10%);
  z-index: 2147483647;
`;

const ModalSkirt = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: rgba(10, 10, 10, 0.5);
  z-index: 1001;
`;

const modalPortal = document.getElementById('modal-root');

export const Modal = (props): JSX.Element =>
  ReactDOM.createPortal(
    <ModalSkirt>
      <ModalWrapper borderRadius={props.borderRadius}>{props.children}</ModalWrapper>
    </ModalSkirt>,
    modalPortal
  );
