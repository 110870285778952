import React, { useState } from 'react';

// Components
import {
  H2,
  Icon,
  Button,
  HorizontalLine,
  TextInput,
  SelectInput,
  InformationColumnBlock,
  InformationRowBlock,
  InformationEditBlock,
  ButtonBlock,
  EditIconConteiner
} from 'es-components';
import { genderDataOptions } from '../../../selectorsData/genderData';

// libs
import { displayDateFormat, auth, validateRequired } from 'es-libs';
import { StaffInformationProps } from './StaffInfoInterface';

interface PersonalInformationProps extends StaffInformationProps {
  fullName?: string;
  dateOfBirth?: string;
  sex?: string;
  firstName?: string;
  lastName?: string;
}

export const PersonalInformation = ({
  fullName,
  dateOfBirth,
  sex,
  firstName,
  lastName,
  onEditModeChange,
  showUpdateButton,
  editMode
}: PersonalInformationProps): JSX.Element => {
  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const [lastNameValue, setLastNameValue] = useState(lastName);
  const [genderValue, setGenderValue] = useState(sex);

  const getEditIcon = (): JSX.Element => {
    const haveAccess = auth.getPermissions().canWriteStaffUsers;
    if (haveAccess) {
      return (
        <EditIconConteiner onClick={onEditModeChange}>
          <Icon name="IoMdCreate" size="1.4em" />
        </EditIconConteiner>
      );
    } else {
      return null;
    }
  };

  const viewInformation = (): JSX.Element => {
    return (
      <>
        <InformationRowBlock>
          <H2 light marginTop="spacingSml">Personal Information</H2>
          {getEditIcon()}
        </InformationRowBlock>
        <HorizontalLine />
        <InformationRowBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Full Name
            </H2>
            <H2 size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {fullName}
            </H2>
          </InformationColumnBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Date Of Birth
            </H2>
            <H2 size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {dateOfBirth ? displayDateFormat(dateOfBirth) : ''}
            </H2>
          </InformationColumnBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Gender
            </H2>
            <H2 size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {sex}
            </H2>
          </InformationColumnBlock>
        </InformationRowBlock>
      </>
    );
  };

  const getButtonBlock = (): JSX.Element => {
    if (showUpdateButton) {
      return (
        <ButtonBlock>
          <Button
            title="Cancel"
            textColor="grey"
            sizeH="short"
            borderColor="grey"
            marginRight="spacingTiny"
            round="10px"
            weight="500"
            fontSize="1.2rem"
            kind="white"
            marginBottom="spacingMinimum"
            onClickHandler={onEditModeChange}
          />
          <Button
            title="Update"
            textColor="white"
            borderColor="green"
            sizeH="short"
            round="10px"
            btnType="submit"
            kind="green"
            marginBottom="spacingMinimum"
            fontSize="1.2rem"
            weight="500"
          />
        </ButtonBlock>
      );
    }
  };

  const onChangeFirstName = value => {
    setFirstNameValue(value.target.value);
  };

  const onChangeLastName = value => {
    setLastNameValue(value.target.value);
  };

  const onChangeGender = value => {
    setGenderValue(value.value);
  };

  const editInformation = (): JSX.Element => {
    return (
      <>
        <InformationEditBlock>
          <InformationRowBlock>
            <H2 marginTop="spacingSml">Personal Information</H2>
            {getButtonBlock()}
          </InformationRowBlock>
          <InformationRowBlock>
            <InformationColumnBlock>
              <TextInput
                onChange={onChangeFirstName}
                value={firstNameValue}
                id="firstName"
                field="firstName"
                label="First Name"
                borderRadius="0"
                validate={validateRequired}
                validateOnChange
              />
            </InformationColumnBlock>
            <InformationColumnBlock>
              <TextInput
                onChange={onChangeLastName}
                value={lastNameValue}
                type="text"
                id="lastName"
                field="lastName"
                label="Last Name"
                borderRadius="0"
                validate={validateRequired}
                validateOnChange
              />
            </InformationColumnBlock>
          </InformationRowBlock>
          <InformationRowBlock className="secondInputRow">
            <InformationColumnBlock>
              <SelectInput
                placeholder="Select gender"
                onChange={onChangeGender}
                value={genderValue}
                id="sex"
                field="sex"
                options={genderDataOptions}
                borderRadius="0"
              />
            </InformationColumnBlock>
            <InformationColumnBlock />
          </InformationRowBlock>
        </InformationEditBlock>
      </>
    );
  };

  return editMode ? editInformation() : viewInformation();
};
