import React, { useState } from 'react';

// Components
import {
  H2,
  Icon,
  HorizontalLine,
  Button,
  TextInput,
  InformationColumnBlock,
  InformationRowBlock,
  InformationEditBlock,
  ButtonBlock,
  EditIconConteiner
} from 'es-components';

//Validations
import { validateAll, validateRequired, validateEmail, validatePassword, validateSamePassword, auth } from 'es-libs';
import { StaffInformationProps } from './StaffInfoInterface';

interface ContactInformationProps extends StaffInformationProps {
  email?: string;
  phone?: string;
}

export const ContactInformation = ({ email, phone, onEditModeChange, showUpdateButton, editMode }: ContactInformationProps): JSX.Element => {
  const [emailValue, setEmailValue] = useState(email);
  const [phoneValue, setPhoneValue] = useState(phone);

  const passWordsValidation = (pw1, pw2) => {
    return validateRequired(pw1) || validatePassword(pw1) || validateSamePassword(pw1, pw2);
  };

  const getEditIcon = (): JSX.Element => {
    const haveAccess = auth.getPermissions().canWriteStaffUsers;
    if (haveAccess) {
      return (
        <EditIconConteiner onClick={onEditModeChange}>
          <Icon name="IoMdCreate" size="1.4em" />
        </EditIconConteiner>
      );
    } else {
      return null;
    }
  };

  const viewInformation = (): JSX.Element => {
    return (
      <>
        <InformationRowBlock>
          <H2 light marginTop="spacingSml">Contact Information</H2>
          {getEditIcon()}
        </InformationRowBlock>
        <HorizontalLine />
        <InformationRowBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Email
            </H2>
            <H2 id="staffEmailDetails" size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {email}
            </H2>
          </InformationColumnBlock>
          <InformationColumnBlock>
            <H2 size="1.1rem" color="greyDark">
              Contact Number
            </H2>
            <H2 size="1.1rem" light color="greyDark" marginTop="spacingTiny">
              {phone}
            </H2>
          </InformationColumnBlock>
          <InformationColumnBlock />
        </InformationRowBlock>
      </>
    );
  };

  const getButtonBlock = (): JSX.Element => {
    if (showUpdateButton) {
      return (
        <ButtonBlock>
          <Button
            title="Cancel"
            textColor="grey"
            sizeH="short"
            borderColor="grey"
            marginRight="spacingTiny"
            round="10px"
            weight="500"
            fontSize="1.2rem"
            kind="white"
            marginBottom="spacingMinimum"
            onClickHandler={onEditModeChange}
          />
          <Button
            title="Update"
            textColor="white"
            borderColor="green"
            sizeH="short"
            round="10px"
            btnType="submit"
            kind="green"
            marginBottom="spacingMinimum"
            fontSize="1.2rem"
            weight="500"
          />
        </ButtonBlock>
      );
    }
  };

  const getPasswordFields = (): JSX.Element => {
    if (!showUpdateButton) {
      return (
        <InformationRowBlock>
          <InformationColumnBlock>
            <TextInput
              validate={(value, values) => passWordsValidation(value, values.passwordConfirmation)}
              validateOnChange
              notify={['passwordConfirmation']}
              type="password"
              id="password"
              field="password"
              label="Password"
              autoComplete="off"
              borderRadius="0"
            />
          </InformationColumnBlock>
          <InformationColumnBlock>
            <TextInput
              validate={(value, values) => passWordsValidation(value, values.password)}
              validateOnChange
              notify={['password']}
              type="password"
              id="passwordConfirmation"
              field="passwordConfirmation"
              label="Confirm password"
              autoComplete="off"
              borderRadius="0"
            />
          </InformationColumnBlock>
        </InformationRowBlock>
      );
    }
  };

  const onChangeEmail = value => {
    setEmailValue(value.target.value);
  };

  const onChangePhone = value => {
    setPhoneValue(value.target.value);
  };

  const editInformation = (): JSX.Element => {
    return (
      <>
        <InformationEditBlock>
          <InformationRowBlock>
            <H2 light marginTop="spacingSml">Contact Information</H2>
            {getButtonBlock()}
          </InformationRowBlock>
          <InformationRowBlock>
            <InformationColumnBlock>
              <TextInput
                onChange={onChangeEmail}
                value={emailValue}
                id="email"
                field="email"
                label="Email Address"
                borderRadius="0"
                validate={validateAll([validateRequired, validateEmail])}
                validateOnChange
              />
            </InformationColumnBlock>
            <InformationColumnBlock>
              <TextInput id="phone" onChange={onChangePhone} value={phoneValue} field="phone" label="Contact Number" autoComplete="off" borderRadius="0" />
            </InformationColumnBlock>
          </InformationRowBlock>
          {getPasswordFields()}
        </InformationEditBlock>
      </>
    );
  };

  return editMode ? editInformation() : viewInformation();
};
