import React from 'react';

// Components
import styled from 'styled-components';
import { H5 } from 'es-components';

const GridHeaderWrapper = styled.div`
  margin-top: -50px;
  float: left;
`;

export const ListingCount = ({ allElements, currentCount }: { allElements: string; currentCount: string }): JSX.Element => {
  return (
    <GridHeaderWrapper>
      <H5 color="greyDark" size="1.4rem">
        Listing {currentCount} from {allElements}
      </H5>
    </GridHeaderWrapper>
  );
};
