import React from 'react';

// Components
import styled from 'styled-components';
import { Button } from 'es-components';
import { Icon } from '../ui';

const NotifHeader = styled.header`
  display: block;
  min-height: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.white};
`;

interface GuestNotificationWrapperI {
  minHeight?: string;
  marginBottom?: string;
  border?: string;
  status?: string;
  rows?: string;
}

export const GuestNotificationWrapper = styled.article<GuestNotificationWrapperI>`
  width: auto;
  height: auto;
  color: ${props => props.theme.white};
  min-height: ${props => props.minHeight || 'auto'};
  margin-bottom: ${props => props.theme[props.marginBottom] || 0};
  box-shadow: ${props => props.theme.boxShadow};
  border: ${props => props.border || props.theme.StdBorder};
  background-color: ${props => {
    switch (props.status) {
      case 'Success':
        return props.theme.success;
      case 'Error':
        return props.theme.error;
      case 'Information':
        return props.theme.info;
      default:
        return props.theme.info;
    }
  }};
  display: grid;
  grid-template-rows: ${props => props.rows || 'auto 1fr auto'};
`;

const HorizontalLine = styled.div`
  width: 100%;
  margin-bottom: 25px;
  border-bottom: 1px solid ${props => props.theme.wgite};
`;

const ContentNotifWrapper = styled.div`
  padding: 1rem;
`;

const ButtonNotifBlock = styled.div`
  box-sizing: border-box
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
  width: 100%
`;

const CloseIconWrapper = styled.div`
  float: right;
  cursor: pointer;
`;

export const GuestDetailsNotify = ({ onCloseNotify, status, text }: { onCloseNotify: () => void; status: string; text: string }): JSX.Element => {
  const getCancelButtonColor = () => {
    switch (status) {
      case 'Success':
        return 'success';
      case 'Error':
        return 'error';
      case 'Information':
        return 'info';
      default:
        return 'info';
    }
  };

  const getActionButtonColor = () => {
    switch (status) {
      case 'Success':
        return 'info';
      case 'Error':
      case 'Information':
        return 'white';
      default:
        return 'white';
    }
  };

  const getActionButtonTextColor = () => {
    switch (status) {
      case 'Success':
        return 'white';
      case 'Error':
        return 'red';
      case 'Information':
        return 'info';
      default:
        return 'info';
    }
  };

  const getNotificationType = () => {
    switch (status) {
      case 'Success':
        return 'Indy Pass Success Notification';
      case 'Error':
        return 'Indy Pass Error Notification';
      case 'Information':
        return 'Indy Pass Information Notification';
      default:
        return 'Indy Pass Information Notification';
    }
  };

  const cancelButtonColor = getCancelButtonColor();
  const actionButtonTextColor = getActionButtonTextColor();
  const actionButtonColor = getActionButtonColor();
  return (
    <GuestNotificationWrapper marginBottom="spacingTiny" status={status}>
      <ContentNotifWrapper>
        <NotifHeader>
          {getNotificationType()}
          <CloseIconWrapper onClick={onCloseNotify}>
            <Icon name="IoIosCloseCircleOutline" size="1.3rem" />
          </CloseIconWrapper>
        </NotifHeader>
        <HorizontalLine />
        {text}
        <ButtonNotifBlock>
          <Button
            title="Cancel"
            borderColor="white"
            marginRight="spacingTiny"
            round="10px"
            kind={cancelButtonColor}
            marginBottom="spacingMinimum"
            sizeW="wide"
          />
          <Button
            title="Action Button"
            textColor={actionButtonTextColor}
            borderColor="white"
            round="10px"
            kind={actionButtonColor}
            marginBottom="spacingMinimum"
            sizeW="wide"
          />
        </ButtonNotifBlock>
      </ContentNotifWrapper>
    </GuestNotificationWrapper>
  );
};
