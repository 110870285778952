import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

// Graph QL
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

// Components
import {
  H2,
  HeaderContentWrapper,
  Pager,
  ListElement,
  ListTableHeader,
  ContentWrapper,
  TablesWrapper,
  ListingCount,
  Error,
  GuestsSearchModal
} from 'es-components';
import { PageLoader } from 'es-containers';
import { GuestsList } from 'es-libs';

interface GuestsListQueryData {
  ticketWindow: { allGuests: GuestsList };
}

interface GuestsListQueryVars {
  search?: string;
  page?: number;
}

const GET_TICKET_WINDOW_GUESTS_QUERY = gql`
  query allGuests($search: String, $page: Int) {
    ticketWindow {
      allGuests(page: $page, filter: { search: $search }) {
        guestCount
        guests {
          id
          email
          phone
          sex
          dateOfBirth
          fullName
          profilePictureUrl
          avatar
          redemptionAvailable
          purchaseDate
        }
      }
    }
  }
`;

export const GuestList = ({ history, location, match }: RouteComponentProps): JSX.Element => {
  const startPage = match.params['page'] || 1;
  const filterString = match.params['filter'] === 'undefined' || match.params['filter'] === 'null' ? '' : match.params['filter'];
  const [search, setSearch] = useState(filterString);
  const [page, setPage] = useState(startPage);
  const [guestList, setGuestList] = useState(null);
  const [pageDataUpdating, setPageDataUpdating] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(true);

  // lazy query
  const [getGuestList, { data: guestListData, loading: guestListLoading, error: guestListError }] = useLazyQuery<GuestsListQueryData, GuestsListQueryVars>(
    GET_TICKET_WINDOW_GUESTS_QUERY
  );

  useEffect(() => {
    if (guestList === null && !pageDataUpdating) {
      setPageDataUpdating(true);
      getGuestList({
        variables: { search, page: +page }
      });
    }
  }, []);

  if (
    !guestListLoading &&
    guestListData != null &&
    (guestList == null || JSON.stringify(guestList) != JSON.stringify(guestListData)) &&
    guestListError == null
  ) {
    setGuestList(guestListData);
    if (search !== undefined && search !== '') {
      setShowSearchModal(false);
    }
  }

  const onSearchIModal = search => {
    setShowSearchModal(false);
    setSearch(search);
    setPage(1);
    getGuestList({
      variables: { search, page: 1 }
    });
  };

  const onSearch = search => {
    if (search === '') {
      setShowSearchModal(true);
    }
    setSearch(search);
    setPage(1);
    getGuestList({
      variables: { search, page: 1 }
    });
  };

  const onPageChange = page => {
    setPage(page);
    getGuestList({
      variables: { search, page }
    });
  };

  const renderBody = () => {
    if (guestListLoading) {
      return <PageLoader show={guestListLoading} />;
    }
    if (!guestListLoading && (guestListError || guestListData === null)) {
      return <Error />;
    }
    if (!guestListLoading && guestListData != null && guestList != null) {
      const { guestCount, guests } = guestList.ticketWindow.allGuests;
      const currentRecordsCount = guests.length;

      return (
        <>
          <ListingCount allElements={guestCount} currentCount={currentRecordsCount} />
          <Pager totalRecords={guestCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
          {guests.map((data, index) => {
            return (
              <Link key={data.id} to={`/guest/${data.id}/${page}/${search}`}>
                <ListElement
                  listType="guest"
                  id={`guestCard_${index}`}
                  userImage={data.profilePictureUrl || data.avatar}
                  key={data.id}
                  redemptionAvailable={data.redemptionAvailable}
                  dateOfBirth={data.dateOfBirth}
                  name={data.fullName}
                  email={data.email}
                  date={data.purchaseDate}
                />
              </Link>
            );
          })}
          <Pager totalRecords={guestCount} recordsOnPage={30} current={page} onPageChanged={onPageChange} />
        </>
      );
    }
  };

  const renderGuestTable = () => {
    return (
      <>
        <ListTableHeader value={search} searchTitle="Search..." onSearch={onSearch} />
        {renderBody()}
      </>
    );
  };

  const renderGuestSearchModal = () => {
    return <GuestsSearchModal onSearch={onSearchIModal} />;
  };

  return (
    <ContentWrapper backgroundColor="white">
      <HeaderContentWrapper backgroundColor="greyLightTint" paddingTop="spacingTiny" paddingBottom="spacingTiny" height="65px">
        <H2 light color="greyBlackShade" size="3rem">
          Guests List
        </H2>
      </HeaderContentWrapper>
      <TablesWrapper>{showSearchModal && filterString === undefined ? renderGuestSearchModal() : renderGuestTable()}</TablesWrapper>
    </ContentWrapper>
  );
};
