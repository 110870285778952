import React from 'react';

// Components
import styled from 'styled-components';
import { GuestDetailsBody } from 'es-components';
import { Guest } from 'es-libs';

export const GuestsWithRedemptionsViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%
  padding: 0.8rem 1rem;
  flex-wrap: wrap
`;

export const GuestsWithRedemptionsView = ({ guests }: { guests: Guest[] }): JSX.Element => {
  return (
    <GuestsWithRedemptionsViewWrapper>
      {guests.map(guest => {
        const userImage = guest.profilePictureUrl || guest.avatar;
        let redemptionActiveStatus = 'InActive';
        if (guest.redemptions.length && guest.redemptions.length <= 3) {
          const match = guest.redemptions.find(redemption => ['full', 'discount'].indexOf(redemption.redemptionLevel) !== -1);
          if (match != null) {
            redemptionActiveStatus = 'Active';
          }
        }

        let productName = '';
        if (guest.accessRecords && guest.accessRecords[0] && guest.accessRecords[0].productJson) {
          productName = JSON.parse(guest.accessRecords[0].productJson).name;
        }

        return (
          <GuestDetailsBody
            key={guest.id}
            userImage={userImage}
            name={guest.fullName}
            phone={guest.phone}
            dateOfBirth={guest.dateOfBirth}
            email={guest.email}
            canResendWaiver={guest.canResendWaiver}
            guestId={guest.id}
            productName={productName}
            purchaseDate={guest.purchaseDate}
            redemptionAvailable={guest.redemptionsAvailable ? guest.redemptionsAvailable.redemptionAvailable : null}
            status={redemptionActiveStatus}
            redemptionsInfo={guest.redemptions}
            accessRecordId={guest.accessRecordId}
            accessPermissionId={guest.accessPermissionId}
            noRedemptionReason={guest.redemptionsAvailable ? guest.redemptionsAvailable.noRedemptionReason : null}
            redemptionsAvailable={guest.redemptionsAvailable ? guest.redemptionsAvailable.redemptionsAvailable : null}
          />
        );
      })}
    </GuestsWithRedemptionsViewWrapper>
  );
};
