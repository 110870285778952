import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { auth, Guest, Resort } from 'es-libs';

// Components
import { Icon, H2, Error } from 'es-components';
import { PageLoader } from 'es-containers';

// Graph QL
import { gql } from 'apollo-boost/lib/index';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

interface GetStaffVars {
  id: string;
}

interface GetStaffData {
  ticketWindow: { staffUser: Guest };
}

const GET_STAFF_QUERY = gql`
  query StaffUser($id: String!) {
    ticketWindow {
      staffUser(id: $id) {
        id
        fullName
        avatar
        resortId
        appTypeField
        profilePictureUrl
      }
    }
  }
`;

interface GetResortVars {
  id: string;
}

interface GetResortData {
  ticketWindow: { resort: Resort };
}

const GET_RESORT_QUERY = gql`
  query Resort($id: String!) {
    ticketWindow {
      resort(id: $id) {
        timeZone
        timeFormat
        dateFormat
      }
    }
  }
`;

const NavPanelWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -34px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
`;

interface NavElementI {
  active?: boolean;
}

const NavElement = styled.div<NavElementI>`
  margin-top: 33px;
  padding-left: 18px;
  width: 90px;
  cursor: pointer;
  height: 86px;
  border-right: 0.5px solid;
  background-color: ${props => (props.active ? props.theme.activeNavBlock : props.theme.primaryShade)};
  border-color: ${props => props.theme.headerActive};
  color: ${props => props.theme.white};
  &:hover {
    background-color: ${props => props.theme.headerActive};
  }
`;

const UserInfoElement = styled.div`
  margin-top: 32px;
  width: 280px;
  height: 87px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.headerActive};
  color: ${props => props.theme.white};
`;

const UserTextInfo = styled.div`
  width: 180px;
  margin-top: 22px;
  color: ${props => props.theme.white};
  margin-left: 58px;
`;

interface userImageI {
  userImage?: string;
}

const UserImage = styled.div<userImageI>`
  background-image: url(${props => props.userImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  width: 60px;
  margin-top: 12px;
  position: absolute;
  right: 4%;
  border-radius: 12px;
  margin-right: 10px;
`;
const IconConteiner = styled.div`
  margin-left: -45px;
  margin-top: 5px;
`;

const PhotoPart = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 12px 0 0 0;
  color: ${props => props.theme.grey};
`;

export const HeaderNavigator = ({ active }: { active: string }): JSX.Element => {
  const [resortDataLoading, setResortDataLoading] = useState(false);

  //query
  const { data, loading, error } = useQuery<GetStaffData, GetStaffVars>(GET_STAFF_QUERY, {
    variables: { id: auth.getAuthenticatedUserId() }
  });

  // lazy query
  const [getResort, { data: resortData, loading: resortLoading }] = useLazyQuery<GetResortData, GetStaffVars>(GET_RESORT_QUERY);

  if (resortData != null && !resortLoading && resortDataLoading && resortData.ticketWindow.resort) {
    const { timeZone, dateFormat } = resortData.ticketWindow.resort;
    let newDateFormatString = '';
    if (dateFormat) {
      let separator = '/';
      if (dateFormat.indexOf('-') != -1) {
        separator = '-';
      } else if (dateFormat.indexOf('/') != -1) {
        separator = '/';
      }
      const format = dateFormat.split('%');
      format.forEach(f => {
        if (f != null) {
          switch (f) {
            case `m${separator}`:
            case 'm':
              newDateFormatString += `MM${separator}`;
              break;
            case `d${separator}`:
            case 'd':
              newDateFormatString += `DD${separator}`;
              break;
            case 'Y':
            case `Y${separator}`:
              newDateFormatString += `YYYY${separator}`;
              break;
            default:
              newDateFormatString += f;
          }
        }
      });
    }

    window.localStorage.setItem('dateFormat', newDateFormatString.slice(0, -1));
    window.localStorage.setItem('timeZone', timeZone);
    setResortDataLoading(false);
  }

  const getUserImage = userImage => {
    if (userImage) {
      return <UserImage userImage={userImage} />;
    } else {
      return (
        <PhotoPart>
          <IconConteiner>
            <Icon name="IoMdImage" size="5em" />
          </IconConteiner>
        </PhotoPart>
      );
    }
  };

  const renderBody = () => {
    if (loading) {
      return <PageLoader left="85%" top="30%" size="70px" marginLeft="40px" show={true} />;
    }
    if (!loading && (error || data === null || data.ticketWindow == null)) {
      return <Error />;
    }

    if (!loading && data != null) {
      const { staffUser } = data.ticketWindow;

      if (staffUser.resortId != null && resortData == null && !resortLoading && !resortDataLoading) {
        getResort({ variables: { id: staffUser.resortId } });
        setResortDataLoading(true);
      }

      const avatar = staffUser.profilePictureUrl || staffUser.avatar;

      return (
        <>
          <UserTextInfo>
            <div style={{ width: '120px', overflow: 'hidden' }}>
              <H2 light color="white" size="1rem">
                {staffUser.fullName}
              </H2>
            </div>
          </UserTextInfo>
          {getUserImage(avatar)}
        </>
      );
    }
  };

  const permissions = auth.getPermissions();
  return (
    <NavPanelWrapper>
      {permissions && permissions.canReadGuests && (
        <Link to={`/guest_list/`}>
          <NavElement active={active === 'guest'}>
            <Icon name="IoIosPeople" size="3em" />
            <H2 light color="white" size="1rem">
              Guests
            </H2>
          </NavElement>
        </Link>
      )}
      {permissions && permissions.canReadReports && (
        <Link to={`/reports/`}>
          <NavElement active={active === 'report'}>
            <Icon name="IoIosAnalytics" size="3em" />
            <H2 light color="white" size="1rem">
              Reports
            </H2>
          </NavElement>
        </Link>
      )}
      {permissions && permissions.canReadStaffUsers && (
        <Link to={`/staff_list/`}>
          <NavElement active={active === 'staff'}>
            <Icon name="IoIosPerson" size="3em" />
            <H2 light color="white" marginLeft="5px" size="1rem">
              Staff
            </H2>
          </NavElement>
        </Link>
      )}
      <Link to={`/logout/`}>
        <NavElement>
          <Icon name="IoIosLogOut" size="3em" />
          <H2 light color="white" size="1rem" data-test="logout-button">
            Log out
          </H2>
        </NavElement>
      </Link>
      {permissions && permissions.canReadStaffUsers && <UserInfoElement>{renderBody()}</UserInfoElement>}
    </NavPanelWrapper>
  );
};
