import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';
import { CookiesProvider } from 'react-cookie';

// GraphQL
import { ApolloClient, HttpLink } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-common';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';

// Context
import { SiteContext } from 'es-context';

// Routes
import routes from './routes';

// Libs
import { auth, getCurrentFrontEndUrl } from 'es-libs';

// Components
import { theme, GlobalStyle, accountTheme } from 'es-themes';
import { Footer, MainHeader } from 'es-containers';
import { SiteWrapper } from 'es-components';
import { Common } from './commons';

// Account Settings
const SITE_THEME = process.env.REACT_APP_SITE_THEME || 'entabeni';
const themedAccount = { ...theme, ...accountTheme[SITE_THEME] };
const API_ENDPOINT = process.env.MAIN_API_ENDPOINT || 'https://entabeni-api-staging.herokuapp.com';
const GA_ID = process.env.REACT_APP_GA_ID;

if (GA_ID) {
  ReactGA.initialize(GA_ID, {
    debug: false,
    titleCase: false
  });
}

const AppBody = withRouter(({ location, history }: RouteComponentProps) => {
  useEffect(() => {
    if (GA_ID) {
      history.listen(location => ReactGA.pageview(location.pathname));
    }
  }, []);

  if (
    location.pathname === '/' ||
    location.pathname === '/login' ||
    location.pathname === '/logout' ||
    location.pathname === '/register' ||
    location.pathname === '/forgotpassword' ||
    location.pathname.indexOf('/resetPassword') !== -1
  ) {
    return routes;
  }

  return (
    <SiteContext.Provider value={{ siteTheme: SITE_THEME }}>
      <SiteWrapper>
        <MainHeader path={location} />
        {routes}
        <Footer />
      </SiteWrapper>
    </SiteContext.Provider>
  );
});

const App = (): JSX.Element => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    setApolloClient();
  }, []);

  const getBaseUrl = () => {
    if (
      (window.location.href.indexOf('localhost') > -1 && process.env.FRONT_END_URL == null) ||
      window.location.href.indexOf('herokuapp') > 1 ||
      process.env.FRONT_END_URL === 'ENTABENI'
    ) {
      return new Promise((resolve, reject) => {
        resolve(Common.FRONT_END_URLS.ENTABENI);
      });
    }

    return fetch(`${API_ENDPOINT}/?frontEndUrl=${getCurrentFrontEndUrl()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Key-Inflection': 'camel'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(respData => {
        const { baseUrl, backgroundImage, logo } = respData;
        themedAccount['logoUrl'] = logo;
        themedAccount['backgroundImage'] = backgroundImage;
        return baseUrl + '/';
      });
  };

  const setApolloClient = () => {
    getBaseUrl().then(baseUrl => {
      if (baseUrl) {
        auth.setBaseUrl(baseUrl);

        const link = new HttpLink({
          uri: `${baseUrl}/graphql`,
          credentials: 'same-origin'
        });

        const authLink = new ApolloLink((operation, forward) => {
          const token = auth.getToken();
          operation.setContext(() => ({
            headers: {
              authorization: token ? `Bearer ${token}` : ''
            }
          }));
          return forward(operation);
        });

        const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
          if (!!graphQLErrors) {
            graphQLErrors.forEach(({ message, path }) => console.log(`[GraphQL Error]: Message: ${message}, Path: ${path}`));
          }

          if (!!networkError) {
            console.log(`[Network error ${operation.operationName}]: Message: ${networkError.message}`);
          }
        });

        const cache = new InMemoryCache({
          dataIdFromObject: object => object.id || null
        });

        const client = new ApolloClient({
          cache,
          link: from([errorLink, authLink, link]),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'network-only'
            },
            query: {
              fetchPolicy: 'network-only'
            }
          }
        });

        setClient(client);
      }
    });
  };

  if (client == null) {
    return null;
  }

  return (
    <CookiesProvider>
      <ThemeProvider theme={themedAccount}>
        <ToastProvider autoDismissTimeout={3000}>
          <ApolloProvider client={client}>
            <Router>
              <>
                <GlobalStyle />
                <AppBody />
              </>
            </Router>
          </ApolloProvider>
        </ToastProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
};

export default App;
