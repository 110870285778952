import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'informed';

// Components
import { Button } from 'es-components';

interface BasicFormI {
  dark?: boolean;
  light?: boolean;
  tint?: boolean;
}

export const BasicForm = styled(Form)<BasicFormI>`
  min-height: 300px;
  width: 100%;
  padding: 1em;

  ${props =>
    props.dark &&
    css`
      border: 1px solid ${props => props.theme.greyDarkShade};
      background-color: ${props => props.theme.greyDarkShade};

      .fieldContainer {
        background-color: ${props => props.theme.greyBlackShade};
      }

      label {
        color: ${props => props.theme.greyTint};
      }

      input {
        color: ${props => props.theme.white};
        border-color: ${props => props.theme.greyBlackShade};
      }

      input[type='radio'],
      input[type='checkbox'] {
        border-color: ${props => props.theme.greyDarkShade};
        background-color: ${props => props.theme.greyBlackShade};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }

        &:checked {
          border-color: ${props => props.theme.greyDark};
          background-color: ${props => props.theme.greyBlackShade};
        }
      }

      input[type='radio'] {
        &:checked:after {
          background: ${props => props.theme.white};
          border: 2px solid ${props => props.theme.greyDark};
        }
      }

      input[type='checkbox'] {
        &:checked:after,
        &:checked:before {
          background: ${props => props.theme.white};
        }
      }

      .react-select__control {
        color: ${props => props.theme.white};
        border: 1px solid ${props => props.theme.greyDarkShade};
        background-color: ${props => props.theme.greyBlackShade};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }
      }

      .react-select__placeholder {
        font-weight: 300;
        color: ${props => props.theme.greyTint};
      }

      .react-select__single-value,
      .react-select__multi-value__label {
        font-weight: 400;
        color: ${props => props.theme.white};
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          background-color: ${props => props.theme.greyDark};
        }
        svg {
          color: ${props => props.theme.greyDark};
        }
      }
    `}

  ${props =>
    props.tint &&
    css`
      border: 1px solid ${props => props.theme.grey};
      background-color: ${props => props.theme.greyLight};

      .fieldContainer {
        background: ${props => props.theme.greyLightTint};
      }

      label {
        color: ${props => props.theme.greyBlackShade};
      }

      input {
        border-color: ${props => props.theme.grey};
        color: ${props => props.theme.greyBlackShade};
      }

      input[type='radio'],
      input[type='checkbox'] {
        border-color: ${props => props.theme.grey};
        background-color: ${props => props.theme.greyLightTint};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }

        &:checked {
          border-color: ${props => props.theme.greyBlackShade};
          background-color: ${props => props.theme.white};
        }
      }

      input[type='radio'] {
        &:checked:after {
          background: ${props => props.theme.greyBlackShade};
          border: 2px solid ${props => props.theme.greyDark};
        }
      }

      input[type='checkbox'] {
        &:checked:after,
        &:checked:before {
          background: ${props => props.theme.greyBlackShade};
        }
      }

      .react-select__control {
        color: ${props => props.theme.greyBlackShade};
        border: 1px solid ${props => props.theme.grey};
        background-color: ${props => props.theme.greyLightTint};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }
      }

      .react-select__placeholder {
        font-weight: 300;
        color: ${props => props.theme.greyBlackShade};
      }

      .react-select__single-value,
      .react-select__multi-value__label {
        font-weight: 400;
        color: ${props => props.theme.greyBlackShade};
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          background-color: ${props => props.theme.grey};
        }
        svg {
          color: ${props => props.theme.greyDark};
        }
      }
    `}

  ${props =>
    props.light &&
    css`
      border: 1px solid ${props => props.theme.grey};
      background-color: ${props => props.theme.white};

      .fieldContainer {
        background: ${props => props.theme.greyLight};
      }

      label {
        color: ${props => props.theme.greyBlackShade};
      }

      input {
        border-color: ${props => props.theme.grey};
        color: ${props => props.theme.greyBlackShade};
      }

      input[type='radio'],
      input[type='checkbox'] {
        border-color: ${props => props.theme.grey};
        background-color: ${props => props.theme.greyLight};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }

        &:checked {
          border-color: ${props => props.theme.greyBlackShade};
          background-color: ${props => props.theme.greyLight};
        }
      }

      input[type='radio'] {
        &:checked:after {
          background: ${props => props.theme.greyBlackShade};
          border: 2px solid ${props => props.theme.greyDark};
        }
      }

      input[type='checkbox'] {
        &:checked:after,
        &:checked:before {
          background: ${props => props.theme.greyBlackShade};
        }
      }

      .react-select__control {
        color: ${props => props.theme.greyBlackShade};
        border: 1px solid ${props => props.theme.grey};
        background-color: ${props => props.theme.greyLight};

        &:hover {
          border-color: ${props => props.theme.greyDark};
        }
      }

      .react-select__placeholder {
        font-weight: 300;
        color: ${props => props.theme.greyBlackShade};
      }

      .react-select__single-value,
      .react-select__multi-value__label {
        font-weight: 400;
        color: ${props => props.theme.greyBlackShade};
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          background-color: ${props => props.theme.grey};
        }
        svg {
          color: ${props => props.theme.greyDark};
        }
      }
    `}
`;
