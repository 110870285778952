export default {
  // Fonts
  fontFamily: `'Roboto', sans-serif`,

  // Validation Colors
  red: '#C10000',
  redTint: '	#FFE5E5',
  redShade: '#950000',
  redLight: '#AA524F',
  green: '#22c639',
  greenShade: '#6C9A30',
  brightGreen: '#119a29',
  disabled: '#999999',
  disabledTint: '#CCCCCC',

  // Default Colors
  black: '#000000',
  white: '#FFFFFF',
  greyLight: '#F1F3F2',
  greyLightTint: '#F8F8F8',
  grey: '#C3C3C3',
  greyTint: '#D9D7D7',
  greyShade: '#A7A6A6',
  brownLight: '#B89E56',
  greyLightest: '#E6E6E6',
  greyDark: '#7B7B7B',
  greyDarkShade: '#464646',
  greyBlack: '#333333',
  greyBlackTint: '#434343',
  greyBlackShade: '#232323',
  headerActive: '#3B4653',
  activeNavBlock: '#B79D5B',
  yellow: '#FFFF00',
  orange: '#FF4500',

  // Notice colors
  info: '#3B4754',
  success: '#35CF24',
  error: '#8A3432',

  // Alpha Colors
  alphaGrey: '180, 180, 180',

  // Layout
  maxWidth: '1200px',

  // Utils
  StdBorder: '2px solid #C3C3C3',
  boxShadow: '0.15em 0.15em 0.6em rgba(100, 100, 100, 0.2)',
  borderRadius: '0.3em',

  // Margin and Padding Spacing
  spacingMinimum: '0.3rem',
  spacingTiny: '0.5rem',
  spacingSml: '1rem',
  spacingMed: '1.4rem',
  spacingLrg: '1.6rem'
};
