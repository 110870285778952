import React, { useState } from 'react';
import styled from 'styled-components';

// Component
import { Modal } from '../Modal';
import { H2, SearchTextInput, Button } from 'es-components';

const BlankModalContainer = styled.div`
  min-height: 100%;
  width: 800px;
  height: 450px;
  background-color: #8b3330;
  position: relative;
  padding-top: 0;
  padding-bottom: 60px;
`;

const ReadyLoaderContainer = styled.div`
  width: 520px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -225px;
`;

export const GuestsSearchModal = ({ onSearch }: { onSearch: (searchString: string) => void }): JSX.Element => {
  const [searchString, setSearchString] = useState(null);

  const onChangeSearch = (searchString: string) => {
    setSearchString(searchString);
  };

  const onSearchButtonClick = () => {
    if (onSearch) {
      onSearch(searchString);
    }
  };

  const getModalBody = () => {
    return (
      <ReadyLoaderContainer>
        <H2 size="3rem" marginBottom="spacingLrg" marginLeft="-20px" color="white">
          Passholder Search
        </H2>
        <SearchTextInput
          id="guestsSearchInput"
          onEnterClick={onSearchButtonClick}
          borderRadius="0"
          width="450px"
          height="100px"
          value={searchString}
          onChangeSearchInput={onChangeSearch}
          color="greyDark"
          searchTitle="Search by First Name, Last Name, Email or Date of Birth"
        />
        <Button
          title="Show Passholders"
          sizeW="widest"
          marginLeft="80px"
          weight="500"
          fontSize="1.6rem"
          textColor="white"
          kind="brownLight"
          borderColor="brownLight"
          onClickHandler={onSearchButtonClick}
        />
      </ReadyLoaderContainer>
    );
  };

  return (
    <Modal borderRadius="0">
      <BlankModalContainer>{getModalBody()}</BlankModalContainer>
    </Modal>
  );
};
