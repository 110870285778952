import React from 'react';
import { BasicText, asField } from 'informed';
import styled, { css } from 'styled-components';
// Components
import { Icon } from 'es-components';

interface SharedFieldStyleI {
  textColor?: string;
}

interface FieldInputI extends SharedFieldStyleI {
  borderColor?: string;
  id?: string;
  height?: string;
  fieldState?: { value?: string; label?: string };
  err?: boolean;
  borderRadius?: string;
  onChange?: any;
}

interface TextFieldContainerI extends SharedFieldStyleI {
  marginTop?: string;
  backgroundColor?: string;
}

interface FieldIconI extends SharedFieldStyleI {
  activeValue?: boolean;
  errorValue?: boolean;
}

interface FieldLabelI extends SharedFieldStyleI {
  activeInput?: boolean;
  hasLayout?: boolean;
}

interface FieldErrorMsgI {
  hasLayout?: boolean;
}

const sharedFieldStyle = css<SharedFieldStyleI>`
  display: flex;
  align-items: center;
  outline: 0;
  z-index: 1;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 400;
  border: 2px solid #7b7b7b;
  color: ${props => props.theme[props.textColor] || props.theme.greyDark};
  transition: border 0.2s, font 0.2s;
  position: relative;
  font-weight: 400;
`;

const FieldInput = styled(BasicText)<FieldInputI>`
  width: 100%;
  border-color: ${props => props.theme[props.borderColor] || props.theme.grey};
  height: ${props => props.height || '40px'};
  padding: ${props => (props.fieldState.value ? '5px 50px 0 10px' : '0 50px 0 15px')};
  color: ${props => props.theme[props.textColor] || props.theme.greyDark};

  ${sharedFieldStyle}

  &:hover {
    transition: all 0.4s;
    border-color: ${props => props.theme.greyDarkShade};
  }

  &:focus {
    border-color: ${props => props.theme.greyDarkShade};
  }

  border-right: ${props => props.err && `2px solid ${props.theme.red}`} !important;
  border-radius: ${props => props.borderRadius || props.theme.borderRadius};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const TextFieldContainer = styled.div<TextFieldContainerI>`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${props => props.marginTop || '5px'};
  background-color: ${props => props.theme[props.backgroundColor] || ''}
  color: ${props => props.theme[props.textColor] || props.theme.greyDark};
  position: relative;
`;

const FieldLabel = styled.label<FieldLabelI>`
  top: ${props => (props.hasLayout ? (props.activeInput ? '5px' : '17px') : '0')};
  left: 15px;
  right: 0;
  bottom: ${props => (!props.hasLayout ? (props.activeInput ? '25px' : '0') : 'auto')};
  z-index: 1;
  font-weight: 400;
  font-size: ${props => (props.activeInput ? '0.6rem' : '1rem')};
  position: absolute;
  display: flex;
  align-items: center;
`;

const FieldErrorMsg = styled.small<FieldErrorMsgI>`
  background-color: ${props => props.theme.red};
  color: ${props => props.theme.white};
  display: block;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 3px 10px;
  text-align: right;
  z-index: 2;

  ${props =>
    !props.hasLayout
      ? css`
          bottom: -12px;
          position: absolute;
          right: 0;
        `
      : ''}
`;

const FieldIcon = styled.span<FieldIconI>`
  position: absolute;
  display: flex;
  width: 50px;
  height: 45px;
  color: ${props => props.theme[props.textColor] || props.theme.greyDark};
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;

  & > svg {
    fill: ${props => props.theme.greyShade};

    ${props =>
      props.activeValue &&
      css`
        fill: ${props => props.theme.primary};
      `}

    ${props =>
      props.errorValue &&
      css`
        fill: ${props => props.theme.red};
      `}
  }
`;

interface InputProps {
  fieldState?: any;
  fieldApi?: any;
  id?: string;
  type?: string;
  inputMask?: string;
  customError?: any;
  icon?: string;
  textColor?: string;
  label?: string;
  field?: string;
  description?: string;
  placeholderText?: string;
  validate?: any;
  value?: any;
  backgroundColor?: string;
  borderRadius?: string;
  inputHelperText?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  forwardedRef?: any;
  handleOnViewPassword?: (e: any) => void;
  inputStyling?: any;
  actionHintStyling?: any;
  labelStyling?: any;
  marginTop?: string;
  serverError?: string;
  validationErrorHasLayout?: boolean;
}

export const TextInput = asField(({ id, fieldState, label, customError, icon, value, onChange, textColor, ...props }: InputProps) => {
  const errorMsg = customError || fieldState.error;

  if (value) {
    fieldState.maskedValue = value;
    fieldState.value = value;
  }

  console.log(props);

  return (
    <TextFieldContainer className="fieldContainer" textColor={textColor} {...props}>
      <FieldLabel textColor={textColor} htmlFor={id} activeInput={fieldState.value != null} hasLayout={props.validationErrorHasLayout}>
        {label}
      </FieldLabel>
      {icon && (
        <FieldIcon textColor={textColor} activeValue={value && value !== 0} errorValue={errorMsg}>
          <Icon name={icon} size="33px" />
        </FieldIcon>
      )}
      <FieldInput
        id={id}
        textColor={textColor}
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
        }}
        fieldState={fieldState}
        err={errorMsg}
        {...props}
      />
      {errorMsg ? <FieldErrorMsg hasLayout={props.validationErrorHasLayout}>{errorMsg}</FieldErrorMsg> : null}
    </TextFieldContainer>
  );
});
